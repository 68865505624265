<template>
    <div style="background: #f9f9f9;height: 100%;min-height: 100dvh;">
        <v-navigation-drawer
            permanent
            app
            class="p-5"
        >
            <img
                src="@/assets/logo.png"
                width="100%"
            >
            <br>
            <h1 class="pl-4 text-xl my-5 font-bold">
                {{ activityData.title }}
            </h1>
            <RecursiveMenu :data="navigation" />
            <template v-slot:append>
                <v-btn
                    block
                    color="error"
                    @click="logout"
                >
                    登出
                </v-btn>
            </template>
        </v-navigation-drawer>
        <v-app-bar
            app
            elevation="4"
            class="text-2xl font-bold"
        >
            {{ $route.meta.title }}
        </v-app-bar>
        <v-main>
            <v-container fluid>
                <router-view />
            </v-container>
        </v-main>
    </div>
</template>

<script>
import RecursiveMenu from '@/components/RecursiveMenu.vue';
import { mapActions, mapState } from 'vuex';

export default {
    components: { RecursiveMenu },
    props: {
        activityId: {
            type: String,
            required: true,
        },
    },
    data() {
        return {
        };
    },
    computed: {
        ...mapState('mActivity', ['activityData']),
        navigation() {
            return [
                {
                    title: '回上頁',
                    goto: '/activity',
                },
                {
                    title: '設置 競賽項目',
                    goto: `/competition/${this.activityId}/item`,
                },
                {
                    title: '管理 活動辦法',
                    goto: `/competition/${this.activityId}/activity`,
                },
                {
                    title: '管理 考試範圍',
                    goto: `/competition/${this.activityId}/scope`,
                },
                {
                    title: '管理 報名資料',
                    goto: `/competition/${this.activityId}/apply`,
                },
                {
                    title: '上傳 參賽證明及得獎證書',
                    goto: `/competition/${this.activityId}/certificate`,
                },
                {
                    title: '管理 整體分數統計數據',
                    goto: `/competition/${this.activityId}/result`,
                },
            ];
        },
    },
    async created() {
        this['mActivity/Fetch'](this.activityId);
    },
    methods: {
        ...mapActions([
            'mActivity/Fetch',
        ]),
        ...mapActions('mAuth', ['Logout']),
        logout() {
            this.Logout();
        },
    },
};
</script>
