<template>
    <li class="recursive-menu-item mb-3 pl-0">
        <slot
            name="title"
            v-bind="data"
        >
            <template v-if="data.children">
                {{ data.title }}
            </template>
            <RouterLink
                v-else-if="data.goto"
                :to="data.goto"
            >
                {{ data.title }}
            </RouterLink>
        </slot>
        <ul v-if="data.children">
            <template v-for="children in data.children">
                <RecursiveMenuItem
                    :key="children.title"
                    :data="children"
                />
            </template>
        </ul>
    </li>
</template>

<script>
// eslint-disable-next-line import/no-self-import
import RecursiveMenuItem from '@/components/RecursiveMenuItem.vue';

export default {
    name: 'RecursiveMenuItem',
    components: {
        RecursiveMenuItem,
    },
    props: {
        data: {
            type: Object,
            required: true,
        },
    },
};
</script>

<style lang="scss" scoped>
a {
    color: theme('colors.gray.500') !important;
}
.router-link-active {
    color: theme('colors.gray.900') !important;
    font-weight: bold;
}
</style>
