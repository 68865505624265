import API from '@/api';
import _ from 'lodash';
import * as utils from '@/store/utils';

const allowKeys = ['type', 'title', 'content'];

export default {
    namespaced: true,
    state: {},
    actions: {
        List(__, params) {
            return API.Question.PrivateList({ params }).catch(utils.handleAPIError);
        },
        Create(__, data) {
            return API.Question.PrivateCreateResource({
                ..._.pick(data, allowKeys),
                online: true,
            })
                .then(() => utils.handleAPISuccess('成功'))
                .catch(utils.handleAPIError);
        },
        Update(__, data) {
            return API.Question.PrivateUpdateResource(data.id, {
                ..._.pick(data, allowKeys),
                online: true,
            })
                .then(() => utils.handleAPISuccess('成功'))
                .catch(utils.handleAPIError);
        },
        Delete(__, id) {
            return API.Question.PrivateDeleteResource(id)
                .then(() => utils.handleAPISuccess('成功'))
                .catch(utils.handleAPIError);
        },
    },
};
