<template>
    <div class="grid gap-2">
        <div class="flex items-center justify-between">
            <div class="flex items-center justify-start gap-2">
                <v-btn-toggle
                    v-model="currentTab"
                    @change="getList()"
                >
                    <template v-for="item in menu">
                        <v-btn
                            :key="item.text"
                            :value="item.value"
                        >
                            {{ item.text }}
                        </v-btn>
                    </template>
                </v-btn-toggle>
                <div class="flex items-center gap-1">
                    <v-text-field
                        v-model="searchText"
                        label="搜尋"
                        outlined
                        hide-details
                        dense
                        class="flex-initial"
                    />
                    <v-btn @click="getList()">
                        搜尋
                    </v-btn>
                </div>
            </div>
            <div class="grid grid-cols-2 gap-2">
                <!-- <v-btn
                    :to="`/competition/${activityId}/apply/create`"
                    color="primary"
                    class="col-span-1"
                >
                    新增項目
                    <v-icon>mdi-plus</v-icon>
                </v-btn> -->
                <v-file-input
                    v-model="excelFile"
                    label="匯入"
                    outlined
                    dense
                    hide-details
                    append-icon="mdi-upload"
                    prepend-icon=""
                    accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                    class="col-span-1"
                    @change="uploadApply"
                >
                    <template v-slot:selection="{ text }">
                        <v-chip
                            small
                            label
                            color="primary"
                        >
                            {{ text }}
                        </v-chip>
                    </template>
                </v-file-input>
                <v-btn
                    color="primary"
                    class="col-span-1"
                    @click="downloadApply()"
                >
                    匯出所有資料
                    <v-icon>mdi-download</v-icon>
                </v-btn>
            </div>
        </div>
        <v-data-table
            :items="list"
            :sort-by="sortBy.key"
            :sort-desc="sortBy.sortDesc"
            :headers="[
                { text: '姓名', value: 'name', sortable: false },
                { text: '身分證', value: 'identity', sortable: false },
                { text: '繳費狀態', value: 'paid', sortable: false },
                { text: '繳費序號', value: 'serial', sortable: false },
                { text: '報名日期', value: 'createTime' },
                { text: '備註欄', value: 'remark',sortable: false },
                { text: '', value: 'actions', sortable: false },
            ]"
            :server-items-length="total"
            :options="paginationOptions"
            :footer-props="{
                'items-per-page-options': [5, 10, 50, 100],
            }"
            @pagination="
                (val) => {
                    paginationOptions = val;
                    getList();
                }
            "
            @update:sort-by="
                (val) => {
                    sortBy.key = val;
                    getList();
                }
            "
            @update:sort-desc="
                (val) => {
                    sortBy.sortDesc = val;
                    getList();
                }
            "
        >
            <template v-slot:item.createTime="{ item }">
                {{ item.createTime | formatDate }}
            </template>
            <template v-slot:item.identity="{ item }">
                {{ item.identity.split('-')[0] }}
            </template>
            <template v-slot:item.paid="{ item }">
                <template v-if="item.paid">
                    已繳費
                </template>
                <template v-else>
                    <strong>未繳費</strong>
                </template>
            </template>
            <template v-slot:item.actions="{ item }">
                <v-btn
                    v-if="currentTab === 'deleted'"
                    :to="`/competition/${activityId}/apply/view/${item.id}`"
                    small
                    class="mr-2"
                >
                    檢視
                </v-btn>
                <v-btn
                    v-else
                    :to="`/competition/${activityId}/apply/${item.id}`"
                    small
                    class="mr-2"
                >
                    編輯
                </v-btn>
                <!-- <v-btn
                    color="error"
                    small
                    class="mr-2"
                    @click="deleteItem(item)"
                >
                    刪除
                </v-btn> -->
                <DialogUI
                    v-if="currentTab !== 'deleted'"
                    :id="item.id"
                    :name="item.name"
                    :identity="item.identity"
                    @record="record"
                />
                <!-- <v-icon
                    small
                    @click="deleteItem(item)"
                >
                    mdi-delete
                </v-icon> -->
            </template>
        </v-data-table>
    </div>
</template>

<script>
import { mapActions } from 'vuex';
import { MessageBox } from 'element-ui';
import { downloadFile } from '@/utils/methods';
import DialogUI from '@/components/DialogUI.vue';

export default {
    components: {
        DialogUI,
    },
    props: {
        activityId: {
            type: String,
            required: true,
        },
    },
    data() {
        return {
            sortBy: {
                key: 'createTime',
                sortDesc: true,
            },
            searchText: '',
            currentTab: '',
            menu: [
                { text: '全部', value: '' },
                { text: '個人', value: JSON.stringify({ $in: [null, ''] }) },
                { text: '團體', value: JSON.stringify({ $nin: [null, ''] }) },
                { text: '刪除', value: 'deleted' },
            ],
            paginationOptions: {
                page: 1,
                itemsPerPage: 10,
            },
            total: 0,
            list: [],
            excelFile: null,
        };
    },
    created() {
        this.getList();
    },
    methods: {
        ...mapActions('mApply', ['List', 'Create', 'Delete', 'Update', 'Download', 'Upload']),
        getList() {
            const { page, itemsPerPage } = this.paginationOptions;
            const sort = JSON.stringify({ [this.sortBy.key]: this.sortBy.sortDesc ? -1 : 1 });
            this.List({
                offset: (page - 1) * itemsPerPage,
                limit: itemsPerPage,
                filter: `activity:${this.activityId}${
                    this.currentTab && this.currentTab !== 'deleted' ? `;groupCellphone:json:${this.currentTab}` : ''
                }`,
                sort,
                search: this.searchText ? this.searchText : undefined,
                stage: this.currentTab === 'deleted' ? 'deleted' : '',
            }).then((res) => {
                this.list = res.data.raw;
                this.total = res.data.total;
            });
        },
        deleteItem(item) {
            MessageBox.confirm(`「${item.name}」的報名資料`, {
                type: 'warning',
                title: '確認刪除？',
                confirmButtonText: '刪除',
                cancelButtonText: '取消',
            })
                .then(() => {
                    this.Delete(item.id).then(this.getList);
                })
                .catch(() => {});
        },
        downloadApply() {
            downloadFile('apply.xlsx', () => this.Download(this.activityId));
        },
        uploadApply(file) {
            if (file) {
                const blobUrl = URL.createObjectURL(file);
                this.Upload({
                    activityId: this.activityId,
                    file: blobUrl,
                }).then(() => {
                    this.excelFile = null;
                    this.getList();
                });
            }
        },
        record() {
            this.getList();
        },
    },
};
</script>
