import _ from 'lodash';
import jwt from 'jsonwebtoken';
import API from '@/api';
import router from '@/router';
import * as utils from '@/store/utils';

const auth = {
    namespaced: true,
    state: {
        token: '',
        data: null,
    },
    mutations: {
        setToken(state, value) {
            state.token = value;
        },
        logout(state) {
            state.data = null;
            state.token = '';
        },
        setData(state, value) {
            state.data = value;
        },
    },
    actions: {
        Sync({
            state, getters, dispatch, commit,
        }, token) {
            try {
                commit('setData', jwt.decode(token));
                if (!['admin'].includes(state.data.role)) {
                    throw new Error('角色錯誤');
                }
                localStorage.setItem('role', state.data.role);
                commit('setToken', token);
            } catch {
                dispatch('Logout');
            }
            if (!getters.isLogin) {
                if (token) {
                    utils.notifyError(new Error('自動登入失敗'));
                }
                dispatch('Logout');
            }
        },
        Login(__, data) {
            return API.Admin.Login(data)
                .catch(utils.handleAPIError);
        },
        Logout({ commit }) {
            localStorage.removeItem('token');
            commit('logout');
            router.push({
                name: 'Login',
            });
        },
    },
    getters: {
        permissions: (state, getters) => {
            if (!getters.isLogin) return false;
            return _.get(state.data, 'permissions', []);
        },
        role: (state, getters) => {
            if (!getters.isLogin) return false;
            return _.get(state.data, 'role', '');
        },
        isLogin: (state) => {
            if (!state.data) return false;
            return Date.now() <= state.data.exp * 1000;
        },
    },
};

export default auth;
