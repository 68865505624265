import Vue from 'vue';
import Vuex from 'vuex';
import Auth from './modules/auth';
import Home from './modules/home';
import Schedule from './modules/schedule';
import Question from './modules/question';
import Setting from './modules/setting';
import Activity from './modules/activity';
import Competition from './modules/competition';
import Scope from './modules/scope';
import Apply from './modules/apply';
import Exam from './modules/exam';

Vue.use(Vuex);

export default new Vuex.Store({
    state: {
    },
    getters: {
    },
    mutations: {
    },
    actions: {
    },
    modules: {
        mAuth: Auth,
        mHome: Home,
        mSchedule: Schedule,
        mQuestion: Question,
        mSetting: Setting,
        mActivity: Activity,
        mCompetition: Competition,
        mScope: Scope,
        mApply: Apply,
        mExam: Exam,
    },
});
