<template>
    <div class="grid gap-1 max-w-xl">
        <RouterLink
            :to="`/competition/${activityId}/apply`"
            class="flex items-center my-5"
        >
            <span class="material-icons text-xl">reply</span>
            &nbsp;
            <span>回上一層</span>
        </RouterLink>
        <v-form
            ref="form"
            lazy-validation
        >
            <table
                class="border-separate"
                style="border-spacing: 20px"
            >
                <tr>
                    <th class="whitespace-nowrap text-left">
                        <p class="mb-7">
                            刪除原因
                        </p>
                    </th>
                    <td class="text-gray-400">
                        <p class="mb-7">
                            {{ formData.deletedReason }}
                        </p>
                    </td>
                </tr>
                <tr>
                    <th class="whitespace-nowrap text-left">
                        <p class="mb-7">
                            報考項目
                        </p>
                    </th>
                    <td class="text-gray-400">
                        <p class="mb-7">
                            {{ formData.competitionDisplay }}
                        </p>
                    </td>
                </tr>
                <tr>
                    <th class="whitespace-nowrap text-left">
                        <p class="mb-7">
                            考場選擇
                        </p>
                    </th>
                    <td class="text-gray-400">
                        <p class="mb-7">
                            {{ formData.place }}
                        </p>
                    </td>
                </tr>
                <tr>
                    <th class="whitespace-nowrap text-left">
                        <p class="mb-7">
                            姓名
                        </p>
                    </th>
                    <td class="text-gray-400">
                        <p class="mb-7">
                            {{ formData.name }}
                        </p>
                    </td>
                </tr>
                <tr>
                    <th class="whitespace-nowrap text-left">
                        <p class="mb-7">
                            性別
                        </p>
                    </th>
                    <td>
                        <v-radio-group
                            v-model="formData.gender"
                            :rules="emptyRule"
                            disabled
                            row
                        >
                            <v-radio
                                label="男"
                                value="男"
                            />
                            <v-radio
                                label="女"
                                value="女"
                            />
                        </v-radio-group>
                    </td>
                </tr>
                <tr>
                    <th class="whitespace-nowrap text-left">
                        <p class="mb-7">
                            准考證號碼
                        </p>
                    </th>
                    <td class="text-gray-400">
                        <p class="mb-7">
                            {{ formData.admitCardId }}
                        </p>
                    </td>
                </tr>
                <tr>
                    <th class="whitespace-nowrap text-left">
                        <p class="mb-7">
                            身分證字號
                        </p>
                    </th>
                    <td class="text-gray-400">
                        <p class="mb-7">
                            {{ formData.identity.split('-')[0] }}
                        </p>
                    </td>
                </tr>
                <tr>
                    <th class="whitespace-nowrap text-left">
                        <p class="mb-7">
                            出生日期
                        </p>
                    </th>
                    <td class="text-gray-400">
                        <p class="mb-7">
                            {{ formData.birthYear }}/
                            {{ formData.birthMonth }}/{{ formData.birthDay }}
                        </p>
                    </td>
                </tr>
                <tr>
                    <th class="whitespace-nowrap text-left">
                        <p class="mb-7">
                            就讀學校
                        </p>
                    </th>
                    <td class="text-gray-400">
                        <p class="mb-7">
                            {{ formData.city }} {{ formData.school }}
                        </p>
                    </td>
                </tr>
                <tr>
                    <th class="whitespace-nowrap text-left">
                        <p class="mb-7">
                            班級
                        </p>
                    </th>
                    <td class="text-gray-400">
                        <p class="mb-7">
                            {{ formData.grade }}年{{ formData.class }}班
                        </p>
                    </td>
                </tr>
                <tr>
                    <th class="whitespace-nowrap text-left">
                        <p class="mb-7">
                            家長姓名
                        </p>
                    </th>
                    <td class="text-gray-400">
                        <p class="mb-7">
                            {{ formData.parentName }}
                        </p>
                    </td>
                </tr>
                <tr>
                    <th
                        class="whitespace-nowrap text-left"
                        colspan="2"
                    >
                        通訊地址(寄送准考證、贈品及試後成績單所用)
                    </th>
                </tr>
                <tr>
                    <td class="text-gray-400">
                        <p class="mb-7">
                            {{ formData.postalCode }}  {{ formData.address }}
                        </p>
                    </td>
                </tr>
                <tr v-if="formData.cellphone">
                    <th class="whitespace-nowrap text-left">
                        <p class="mb-7">
                            手機（個人）
                        </p>
                    </th>
                    <td class="text-gray-400">
                        <p class="mb-7">
                            {{ formData.cellphone }}
                        </p>
                    </td>
                </tr>
                <tr v-if="formData.telephone">
                    <th class="whitespace-nowrap text-left">
                        <p class="mb-7">
                            電話
                        </p>
                    </th>
                    <td class="text-gray-400">
                        <p class="mb-7">
                            {{ formData.telephone }}
                        </p>
                    </td>
                </tr>
                <tr v-if="formData.groupCellphone">
                    <th class="whitespace-nowrap text-left">
                        手機（團體）
                    </th>
                    <td class="text-gray-400">
                        <p>{{ formData.groupCellphone }}</p>
                    </td>
                </tr>
                <tr>
                    <th class="whitespace-nowrap text-left">
                        報名日期
                    </th>
                    <td class="text-gray-400">
                        {{ formData.createTime | formatDate }}
                    </td>
                </tr>
                <tr v-if="!formData.paid">
                    <th class="whitespace-nowrap text-left">
                        繳費序號
                    </th>
                    <td class="text-gray-400">
                        {{ formData.serial }}（未繳費）
                    </td>
                </tr>
            </table>
        </v-form>
    </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import { MessageBox } from 'element-ui';
import _ from 'lodash';
import formOption from '@/assets/form-options.json';
import { generateNumberArray, getNowChineseYear } from '@/utils/methods';
import { isNationalIdentificationNumberValid, isNewResidentCertificateNumberValid } from 'taiwan-id-validator';

const initData = () => ({
    paid: false,
    activity: '',
    activityDisplay: '',
    competition: '',
    competitionDisplay: '',
    place: '',
    name: '',
    gender: '',
    identity: '',
    birthYear: '',
    birthMonth: '',
    birthDay: '',
    city: '',
    school: '',
    grade: '',
    class: '',
    parentName: '',
    telephone: '',
    cellphone: '',
    postalCode: '',
    address: '',
    remark: '',
    groupCellphone: '',
    groupPassword: '',
    admitCardId: '',
    date: '',
    score: '',
    prize: '',
    rank: '',
    percentileRank: '',
    certification: '',
    certificationItem: '',
    certificationClass: '',
    certificationPrize: '',
    certificationYear: '',
    certificationMonth: '',
    certificationDay: '',
    hash: '',
    serial: '',
    subjects: [],
    createTime: '',
    id: '',
});
export default {
    props: {
        id: {
            type: String,
            default: () => 'create',
        },
        activityId: {
            type: String,
            default: () => '',
        },
    },
    data() {
        return {
            formOption,
            formData: initData(),
            emptyRule: [(v) => !!v || '此欄位為必填'],
            identity: [
                (v) => isNationalIdentificationNumberValid(v) || isNewResidentCertificateNumberValid(v) || '身份證 格式有誤',
            ],
        };
    },
    computed: {
        ...mapState('mCompetition', ['competitionList']),
        fieldOptions() {
            return this.formData.competition
                ? this.competitionList.find(({ id }) => this.formData.competition === id)?.locations
                : [];
        },
    },
    created() {
        this['mCompetition/List']({
            filter: `activity:${this.activityId}`,
        });
        if (this.id !== 'create') {
            this.FetchView(this.id).then((res) => {
                _.assign(this.formData, res.data);
            });
        }
    },
    methods: {
        ...mapActions('mApply', ['FetchView', 'Create', 'Delete', 'Update', 'UpdatePayStatus']),
        ...mapActions(['mCompetition/List']),
        generateNumberArray,
        getNowChineseYear,
        clearForm() {
            this.formData = initData();
            setTimeout(this.$refs.form.reset, 100);
        },
        handleSubmit() {
            if (this.$refs.form.validate()) {
                if (this.id && this.id !== 'create') {
                    console.log(this.formData);
                    this.Update({ id: this.id, data: this.formData });
                } else {
                    this.Create(this.formData).then((res) => {
                        this.$router.push(`/competition/${this.activityId}/apply/${res.data.id}`);
                    });
                }
            }
        },
        handleDelete() {
            MessageBox.confirm(this.formData.name, {
                type: 'warning',
                title: '確認刪除？',
                confirmButtonText: '刪除',
                cancelButtonText: '取消',
            })
                .then(() => {
                    this.Delete(this.id).then(() => this.$router.push(`/competition/${this.activityId}/apply`));
                })
                .catch(() => {});
        },
        record() {
            this.$router.push(`/competition/${this.activityId}/apply`);
        },
    },
};
</script>
<style lang="scss">
.delete-absolute-btn{
    position: absolute;
    top: 50px;
    right: 30px;
    .v-btn{
        padding: 0 20px !important;
    }
}
</style>
