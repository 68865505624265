var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"grid gap-2"},[_c('div',{staticClass:"flex justify-end"},[_c('v-btn',{attrs:{"color":"primary","to":"/activity/create"}},[_vm._v(" 新增活動 "),_c('v-icon',[_vm._v("mdi-plus")])],1)],1),_c('v-data-table',{attrs:{"disable-sort":"","items":_vm.list,"headers":[
            { text: '名稱', value: 'name' },
            {
                text: '',
                value: 'edit',
                align: 'center',
                sortable: false,
                width: 100,
                divider: true,
            },
            {
                text: '設定活動',
                value: 'setting',
                align: 'center',
                sortable: false,
                width: 100,
                divider: true,
            },
            {
                text: '選擇當前活動',
                value: 'select',
                align: 'center',
                sortable: false,
                width: 130,
                divider: true,
            },
            {
                text: '',
                value: 'delete',
                align: 'center',
                sortable: false,
                width: 100,
            },
        ],"server-items-length":_vm.total,"options":_vm.paginationOptions,"footer-props":{
            'items-per-page-options': [5, 10, 50, 100],
        }},on:{"update:options":function($event){_vm.paginationOptions=$event}},scopedSlots:_vm._u([{key:"item.edit",fn:function({ item }){return [_c('v-btn',{attrs:{"to":`/activity/${item.id}`}},[_vm._v(" 編輯 ")])]}},{key:"item.setting",fn:function({ item }){return [_c('v-btn',{attrs:{"to":`/competition/${item.id}`,"color":"#444444","dark":""}},[_vm._v(" 設定 ")])]}},{key:"item.select",fn:function({ item }){return [_c('v-btn',{attrs:{"text":"","disabled":_vm.active(item)},on:{"click":function($event){return _vm.changeCurrent(item)}}},[_vm._v(" "+_vm._s(_vm.active(item) ? "前台顯示中" : "設為當前活動")+" ")])]}},{key:"item.delete",fn:function({ item }){return [_c('v-btn',{attrs:{"icon":"","text":""},on:{"click":function($event){return _vm.deleteItem(item)}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-delete ")])],1)]}}])})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }