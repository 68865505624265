<template>
    <div class="grid gap-2">
        <div class="flex items-center justify-between">
            <div class="flex items-center">
                <p class="font-bold text-xl mb-0 mr-4">
                    輪播照片編輯
                </p>
                <small class="text-gray-400">*不得少於三項，建議檔案大小3m以下，寬不得大於 1600px</small>
            </div>
            <v-btn
                color="primary"
                :disabled="!createable"
                @click="banners.push({ file: '', id: '' })"
            >
                新增項目
                <v-icon>mdi-plus</v-icon>
            </v-btn>
        </div>
        <div
            v-for="(item, i) in banners"
            :key="i"
            class="flex items-center justify-start mb-5"
        >
            <ImageUploader
                v-model="banners[i].file"
                @change="uploadFile(banners[i])"
            />
            <v-btn
                icon
                @click="handleDelete(item.id, i)"
            >
                <v-icon>mdi-delete</v-icon>
            </v-btn>
        </div>
    </div>
</template>

<script>
import { mapActions } from 'vuex';
import ImageUploader from '@/components/ImageUploader.vue';

export default {
    components: {
        ImageUploader,
    },
    data() {
        return {
            banners: [],
        };
    },
    computed: {
        createable() {
            return this.banners.every((item) => item.id);
        },
    },
    created() {
        this.List().then(({ data }) => {
            this.banners = data.raw;
        });
    },
    methods: {
        ...mapActions('mHome', ['List', 'Update', 'Create', 'Delete', 'convertForm']),
        uploadFile({ id, file }) {
            if (file) {
                if (id) {
                    this.Update({ id, file });
                } else {
                    this.Create({ id, file }).then((res) => {
                        // 把 id 塞回新建的 file
                        this.banners[this.banners.findIndex((item) => !item.id)].id = res.data.id;
                    });
                }
            }
        },
        handleDelete(id, index) {
            if (id) {
                this.Delete(id).then(() => {
                    this.banners.splice(index, 1);
                });
            } else this.banners.splice(index, 1);
        },
    },
};
</script>
