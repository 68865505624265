var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"grid gap-2"},[_c('div',{staticClass:"flex items-center justify-between"},[_c('div',{staticClass:"flex items-center justify-start gap-2"},[_c('v-btn-toggle',{on:{"change":function($event){return _vm.getList()}},model:{value:(_vm.currentTab),callback:function ($$v) {_vm.currentTab=$$v},expression:"currentTab"}},[_vm._l((_vm.menu),function(item){return [_c('v-btn',{key:item.text,attrs:{"value":item.value}},[_vm._v(" "+_vm._s(item.text)+" ")])]})],2),_c('div',{staticClass:"flex items-center gap-1"},[_c('v-text-field',{staticClass:"flex-initial",attrs:{"label":"搜尋","outlined":"","hide-details":"","dense":""},model:{value:(_vm.searchText),callback:function ($$v) {_vm.searchText=$$v},expression:"searchText"}}),_c('v-btn',{on:{"click":function($event){return _vm.getList()}}},[_vm._v(" 搜尋 ")])],1)],1),_c('div',{staticClass:"grid grid-cols-2 gap-2"},[_c('v-file-input',{staticClass:"col-span-1",attrs:{"label":"匯入","outlined":"","dense":"","hide-details":"","append-icon":"mdi-upload","prepend-icon":"","accept":"application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"},on:{"change":_vm.uploadApply},scopedSlots:_vm._u([{key:"selection",fn:function({ text }){return [_c('v-chip',{attrs:{"small":"","label":"","color":"primary"}},[_vm._v(" "+_vm._s(text)+" ")])]}}]),model:{value:(_vm.excelFile),callback:function ($$v) {_vm.excelFile=$$v},expression:"excelFile"}}),_c('v-btn',{staticClass:"col-span-1",attrs:{"color":"primary"},on:{"click":function($event){return _vm.downloadApply()}}},[_vm._v(" 匯出所有資料 "),_c('v-icon',[_vm._v("mdi-download")])],1)],1)]),_c('v-data-table',{attrs:{"items":_vm.list,"sort-by":_vm.sortBy.key,"sort-desc":_vm.sortBy.sortDesc,"headers":[
            { text: '姓名', value: 'name', sortable: false },
            { text: '身分證', value: 'identity', sortable: false },
            { text: '繳費狀態', value: 'paid', sortable: false },
            { text: '繳費序號', value: 'serial', sortable: false },
            { text: '報名日期', value: 'createTime' },
            { text: '備註欄', value: 'remark',sortable: false },
            { text: '', value: 'actions', sortable: false },
        ],"server-items-length":_vm.total,"options":_vm.paginationOptions,"footer-props":{
            'items-per-page-options': [5, 10, 50, 100],
        }},on:{"pagination":(val) => {
                _vm.paginationOptions = val;
                _vm.getList();
            },"update:sort-by":(val) => {
                _vm.sortBy.key = val;
                _vm.getList();
            },"update:sort-desc":(val) => {
                _vm.sortBy.sortDesc = val;
                _vm.getList();
            }},scopedSlots:_vm._u([{key:"item.createTime",fn:function({ item }){return [_vm._v(" "+_vm._s(_vm._f("formatDate")(item.createTime))+" ")]}},{key:"item.identity",fn:function({ item }){return [_vm._v(" "+_vm._s(item.identity.split('-')[0])+" ")]}},{key:"item.paid",fn:function({ item }){return [(item.paid)?[_vm._v(" 已繳費 ")]:[_c('strong',[_vm._v("未繳費")])]]}},{key:"item.actions",fn:function({ item }){return [(_vm.currentTab === 'deleted')?_c('v-btn',{staticClass:"mr-2",attrs:{"to":`/competition/${_vm.activityId}/apply/view/${item.id}`,"small":""}},[_vm._v(" 檢視 ")]):_c('v-btn',{staticClass:"mr-2",attrs:{"to":`/competition/${_vm.activityId}/apply/${item.id}`,"small":""}},[_vm._v(" 編輯 ")]),(_vm.currentTab !== 'deleted')?_c('DialogUI',{attrs:{"id":item.id,"name":item.name,"identity":item.identity},on:{"record":_vm.record}}):_vm._e()]}}])})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }