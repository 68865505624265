<template>
    <div style="background: #f9f9f9;height: 100%;min-height: 100dvh;">
        <v-navigation-drawer
            permanent
            app
            class="p-5"
        >
            <img
                src="@/assets/logo.png"
                width="100%"
            >
            <br>
            <RecursiveMenu :data="navigation" />
            <template v-slot:append>
                <v-btn
                    block
                    color="error"
                    @click="logout"
                >
                    登出
                </v-btn>
            </template>
        </v-navigation-drawer>
        <v-app-bar
            app
            elevation="4"
            class="text-2xl font-bold"
        >
            {{ $route.meta.title }}
        </v-app-bar>
        <v-main>
            <v-container fluid>
                <router-view />
            </v-container>
        </v-main>
    </div>
</template>

<script>
import RecursiveMenu from '@/components/RecursiveMenu.vue';
import { mapActions } from 'vuex';

export default {
    components: { RecursiveMenu },
    data() {
        return {
            navigation: [
                {
                    title: '管理 首頁輪播圖',
                    goto: '/home',
                },
                {
                    title: '管理 NEST考試日程',
                    goto: '/schedule',
                },
                {
                    title: '管理 常見問題',
                    goto: '/question',
                },
                {
                    title: '管理 退費辦法/活動須知',
                    goto: '/setting',
                },
                {
                    title: '創建活動',
                    goto: '/activity',
                },
                {
                    title: '上傳 歷屆試題',
                    goto: '/history',
                },
            ],
        };
    },
    methods: {
        ...mapActions('mAuth', ['Logout']),
        logout() {
            this.Logout();
        },
    },
};
</script>

<style lang="scss" scoped>
</style>
