import API from '@/api';
import * as utils from '@/store/utils';

export default {
    namespaced: true,
    state: {
        settings: {
            說明事項: '',
            應試規則: '',
            退費辦法: '',
            當前活動: '',
        },
    },
    actions: {
        PublicInfo({ state }) {
            return API.Setting.PublicInfo()
                .then((res) => {
                    state.settings = res.data;
                    return res;
                })
                .catch(utils.handleAPIError);
        },
        List() {
            return API.Setting.List().catch(utils.handleAPIError);
        },
        Update(__, { key, value }) {
            return API.Setting.Update(key, value)
                .then(() => utils.handleAPISuccess('成功'))
                .catch(utils.handleAPIError);
        },
        UpdateMultiple(__, data) {
            return API.Setting.Updates(data)
                .then(() => utils.handleAPISuccess('成功'))
                .catch(utils.handleAPIError);
        },
    },
};
