<template>
    <v-dialog
        v-model="dialog"
        scrollable
        max-width="800px"
    >
        <template v-slot:activator="{ on, attrs }">
            <v-btn
                color="error"
                small
                class="mr-2"
                v-bind="attrs"
                v-on="on"
            >
                刪除
            </v-btn>
        </template>
        <v-card class="dialog-box">
            <v-card-title
                v-if="!isDoubleCheck"
                class="card-title"
            >
                刪除報名資料
            </v-card-title>
            <v-card-text
                v-if="isDoubleCheck"
                class="card-text"
            >
                <div class="message">
                    是否確認要刪除<br>
                    「 {{ name }} {{ identity }} 」報名資料？
                </div>
                <p style="text-align: center; padding-bottom: 42px">
                    刪除原因： {{ reason==='其他：'?other:reason }}
                </p>
            </v-card-text>
            <v-card-text
                v-else
                class="card-text"
            >
                <p>刪除「 {{ name }} {{ identity }} 」報名資料，原因：</p>
                <v-radio-group
                    v-model="reason"
                    column
                >
                    <v-radio
                        label="已退費"
                        value="已退費"
                    />
                    <v-radio
                        label="未繳費，報名錯誤"
                        value="未繳費，報名錯誤"
                    />
                    <div class="radio-other">
                        <v-radio
                            label="其他："
                            value="其他："
                        />
                        <v-text-field
                            v-model="other"
                            label="請輸入原因"
                            single-line
                        />
                    </div>
                </v-radio-group>
            </v-card-text>
            <v-card-actions class="card-btn">
                <v-btn
                    rounded
                    dark
                    class="button ma-auto d-block px-16"
                    :style="{
                        background: 'var(--color-primary-gradient)',
                    }"
                    @click="confirm()"
                >
                    確認
                </v-btn>
                <v-btn
                    rounded
                    dark
                    class="button ma-auto d-block px-16"
                    :style="{
                        border: '3px solid #29bca2',
                        background: '#fff',
                        color: '#29bca2',
                    }"
                    @click="cancel()"
                >
                    取消
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>
<script>
import API from '@/api';
import { mapActions } from 'vuex';
import * as utils from '../store/utils';

export default {
    model: {
        event: 'record',
    },
    props: {
        id: {
            type: String,
            required: true,
        },
        name: {
            type: String,
            required: true,
        },
        identity: {
            type: String,
            required: true,
        },
    },
    data() {
        return {
            isDoubleCheck: false,
            reason: '',
            dialog: '',
            other: '',
        };
    },
    methods: {
        ...mapActions('mApply', ['Delete']),
        cancel() {
            this.reason = '';
            this.isDoubleCheck = false;
            this.other = '';
            this.dialog = false;
        },
        async confirm() {
            if (this.isDoubleCheck) {
                try {
                    const value = this.reason === '其他：' ? this.other : this.reason;
                    await API.ApplyUtil.SoftDelete(this.id, value);
                    this.cancel();
                    this.$emit('record');
                    utils.handleAPISuccess('刪除成功');
                } catch (e) {
                    this.dialog = false;
                    utils.handleAPIError('刪除失敗', e);
                }
            } else {
                this.isDoubleCheck = true;
            }
        },
    },
};
</script>
<style lang="scss">
.dialog-box.v-card {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 47px 0;
    border-radius: 11px;
    .card-title {
        font-size: 28px !important;
        justify-content: center;
        font-weight: 400 !important;
        padding: 0 0 28px !important;
    }
    p {
        font-size: 18px;
        color: #000;
        margin-bottom: 0px !important;
    }
    .message {
        font-size: 22px;
        color: #000;
        padding: 37px 0 21px;
        text-align: center;
        line-height: 1.5;
    }
    .v-input {
        margin: 30px 0 16px !important;
        padding-top: 0 !important;
    }
    .v-input__slot {
        margin-bottom: 0 !important;
    }
    .card-text {
        max-width: 548px;
    }
    .card-btn {
        width: 100%;
        max-width: 500px;
        padding: 0px !important;
        .button {
            width: 48%;
            font-size: 19px;
            font-weight: 700;
            padding: 13px 0;
            height: auto;
        }
    }
    label {
        font-size: 18px;
        color: #000;
    }
    .radio-other {
        display: flex;
        .v-input {
            margin: 0 !important;
        }
        .v-text-field__details {
            display: none;
        }
        .v-input__control {
            margin-top: -4px;
        }
        .v-text-field__slot label {
            opacity: 0.4;
        }
    }
}
</style>
