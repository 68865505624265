import API from '@/api';
import _ from 'lodash';
import * as utils from '@/store/utils';

export default {
    namespaced: true,
    state: {},
    actions: {
        List() {
            return API.Carousel.PrivateList().catch(utils.handleAPIError);
        },
        async Create({ dispatch }, { file }) {
            return API.Carousel.PrivateCreateResource(
                await dispatch('convertForm', {
                    file,
                    online: true,
                }),
            )
                .then(() => utils.handleAPISuccess('成功'))
                .catch(utils.handleAPIError);
        },
        async Update({ dispatch }, { id, file }) {
            return API.Carousel.PrivateUpdateResource(
                id,
                await dispatch('convertForm', {
                    file,
                    online: true,
                }),
            )
                .then(() => utils.handleAPISuccess('成功'))
                .catch(utils.handleAPIError);
        },
        Delete(__, id) {
            return API.Carousel.PrivateDeleteResource(id)
                .then(() => utils.handleAPISuccess('成功'))
                .catch(utils.handleAPIError);
        },
        convertForm(__, form) {
            const data = _.cloneDeep(form);
            return new API.FormData(data, [
                {
                    urls: _.filter([data.file], Boolean),
                    ext: (mimetype) => ({
                        'image/png': 'png',
                        'image/jpeg': 'jpg',
                        'application/pdf': 'pdf',
                    }[mimetype] || 'png'),
                },
            ]);
        },
    },
};
