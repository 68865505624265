<script>
import { Message } from 'element-ui';

export default {
    model: {
        prop: 'modelValue',
        event: 'change',
    },
    props: {
        modelValue: {
            type: String,
            required: true,
            default: () => null,
        },
        accept: {
            type: String,
            default: () => '',
        },
        rules: {
            type: [Array, Object],
            default: () => [],
        },
        label: {
            type: String,
            default: () => '選擇檔案',
        },
    },
    data() {
        return {
            fileData: null,
            oldFile: '',
            oldUrl: '',
        };
    },
    computed: {
        fileModel: {
            get() {
                return this.fileData;
            },
            set(file) {
                this.fileData = file;
                if (!file) {
                    this.$emit('change', '');
                } else if (file.size > 1024 * 1024 * 10) {
                    Message.error({
                        message: '大小超過 10 MB',
                    });
                } else {
                    this.$emit('change', URL.createObjectURL(file));
                }
            },
        },
    },
    watch: {
        modelValue(__, oldVal) {
            if (oldVal) {
                this.oldUrl = oldVal;
            }
        },
    },
};
</script>

<template>
    <div class="d-flex align-center">
        <template v-if="modelValue">
            <a
                :href="$static(modelValue)"
                target="_blank"
            >預覽檔案</a>
            <v-btn
                icon
                class="ml-5"
                @click="fileModel = null"
            >
                <v-icon>mdi-delete</v-icon>
            </v-btn>
        </template>
        <template v-else>
            <v-file-input
                v-model="fileModel"
                :accept="accept"
                :rules="rules"
                :label="label"
                show-size
            />
            <v-btn
                color="info"
                small
                class="ml-5"
                @click="$emit('change', oldUrl); "
            >
                取消
            </v-btn>
        </template>
    </div>
</template>
