<template>
    <div class="content">
        <h2 class="title">
            參賽證明
        </h2>
        <!-- TODO:待串接 -->
        <ImageUploader
            v-model="participationTemplate"
            class="image-uploader"
            @change="uploadFileParticipation"
        />
        <h2 class="title">
            得獎證書
        </h2>
        <ImageUploader
            v-model="certificateTemplate"
            class="image-uploader"
            @change="uploadFileCertificate"
        />
    </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import ImageUploader from '@/components/ImageUploader.vue';

export default {
    components: {
        ImageUploader,
    },
    // eslint-disable-next-line vue/require-prop-types
    props: ['activityId'],
    computed: {
        ...mapState('mActivity', ['activityData']),
        // TODO:待串接
        certificateTemplate: {
            get() {
                return this.activityData.certificateTemplate || '';
            },
            set(val) {
                this.activityData.certificateTemplate = val;
            },
        },
        participationTemplate: {
            get() {
                return this.activityData.participationTemplate || '';
            },
            set(val) {
                this.activityData.participationTemplate = val;
            },
        },
    },
    methods: {
        ...mapActions('mActivity', ['Update']),
        // TODO:待串接
        uploadFileCertificate(file) {
            if (file) {
                this.Update({
                    id: this.activityId,
                    data: {
                        certificateTemplate: file,
                    },
                });
            }
        },
        uploadFileParticipation(file) {
            if (file) {
                this.Update({
                    id: this.activityId,
                    data: {
                        participationTemplate: file,
                    },
                });
            }
        },
    },
};
</script>

<style lang="scss" scoped>
.content{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
}
.title{
    font-size: 16px;
    background-color: #fff;
    padding: 17px 15px;
    box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.02), 0px 4px 5px 0px rgba(0, 0, 0, 0.02),
     0px 1px 10px 0px rgba(0, 0, 0, 0.02);
}
.image-uploader{
    padding: 15px 60px;
}
</style>
