import API from '@/api';
import _ from 'lodash';
import * as utils from '@/store/utils';

const allowKeys = [
    'name',
    'title',
    'applyStart',
    'applyEnd',
    'payDeadline',
    'publicResultTime',
    'online',
    'qualification',
    'fee',
    'giveaway',
    'prize',
    'examTimetables',
    'certificateTemplate',
    'participationTemplate',
    'result',
];

export default {
    namespaced: true,
    state: {
        activityData: {
            title: '',
            name: '',
            applyStart: '',
            applyEnd: '',
            payDeadline: '',
            publicResultTime: '',
            online: true,
            qualification: '',
            fee: '',
            giveaway: {
                thumb: '',
                title: '',
                description: '',
            },
            prize: {
                thumb: '',
                title: '',
                description: '',
            },
            examTimetables: [],
        },
    },
    actions: {
        Fetch({ state }, id) {
            return API.Activity.PrivateFetchResource(id)
                .then((res) => {
                    state.activityData = res.data;
                    return res;
                })
                .catch(utils.handleAPIError);
        },
        List(__, params) {
            return API.Activity.PrivateList({ params }).catch(utils.handleAPIError);
        },
        Create(__, data) {
            return API.Activity.PrivateCreateResource({
                ..._.pick(data, allowKeys),
                online: true,
            })
                .then((res) => {
                    utils.handleAPISuccess('成功');
                    return res;
                })
                .catch(utils.handleAPIError);
        },
        // TODO:待串接
        async Update({ dispatch }, { id, data }) {
            return API.Activity.PrivateUpdateResource(
                id,
                await dispatch('convertForm', {
                    ..._.pick(data, allowKeys),
                    online: true,
                }),
            )
                .then(() => utils.handleAPISuccess('成功'))
                .catch(utils.handleAPIError);
        },
        Delete(__, id) {
            return API.Activity.PrivateDeleteResource(id)
                .then(() => utils.handleAPISuccess('成功'))
                .catch(utils.handleAPIError);
        },
        convertForm(__, form) {
            const data = _.cloneDeep(form);
            return new API.FormData(data, [
                {
                    urls: []
                        .concat(data.giveaway?.thumb || [])
                        .concat(data.prize?.thumb || [])
                        .concat(data.certificateTemplate || [])
                        .concat(data.participationTemplate || [])
                        .concat(data.examTimetables || [])
                        .concat(data.result?.map(({ image }) => image) || []),
                    ext: (mimetype) => ({
                        'image/png': 'png',
                        'image/jpeg': 'jpg',
                        'application/pdf': 'pdf',
                    }[mimetype] || 'png'),
                },
            ]);
        },
    },
};
