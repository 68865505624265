import API from '@/api';
import _ from 'lodash';
import * as utils from '@/store/utils';

const allowCreateKeys = [
    'activity',
    'date',
    'time',
    'name',
    'locations',
    'fee',
    'weight',
    'online',
    'examTimetable',
];
const allowUpdateKeys = ['date', 'time', 'name', 'locations', 'fee', 'weight', 'examTimetable'];

export default {
    namespaced: true,
    state: {
        competitionList: [],
    },
    actions: {
        Fetch(__, id) {
            return API.Competition.PrivateFetchResource(id).catch(utils.handleAPIError);
        },
        List({ state }, params) {
            return API.Competition.PrivateList({ params })
                .then((res) => {
                    state.competitionList = res.data.raw;
                    return res;
                })
                .catch(utils.handleAPIError);
        },
        async Create({ dispatch }, data) {
            return API.Competition.PrivateCreateResource(
                await dispatch('convertForm', {
                    ..._.pick(data, allowCreateKeys),
                    online: true,
                }),
            )
                .then((res) => {
                    utils.handleAPISuccess('成功');
                    return res;
                })
                .catch(utils.handleAPIError);
        },
        async Update({ dispatch }, { id, data }) {
            console.log(data);
            return API.Competition.PrivateUpdateResource(
                id,
                await dispatch('convertForm', {
                    ..._.pick(data, allowUpdateKeys),
                    online: true,
                }),
            )
                .then(() => utils.handleAPISuccess('成功'))
                .catch(utils.handleAPIError);
        },
        Delete(__, id) {
            return API.Competition.PrivateDeleteResource(id)
                .then(() => utils.handleAPISuccess('成功'))
                .catch(utils.handleAPIError);
        },
        convertForm(__, form) {
            const data = _.cloneDeep(form);
            return new API.FormData(data, [
                {
                    urls: [
                        ...data.locations.map(({ planimetricMap }) => planimetricMap),
                        data.examTimetable,
                    ],
                    ext: (mimetype) => ({
                        'image/png': 'png',
                        'image/jpeg': 'jpg',
                        'application/pdf': 'pdf',
                    }[mimetype] || 'png'),
                },
            ]);
        },
    },
};
