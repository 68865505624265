var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"grid gap-2"},[_c('div',{staticClass:"flex justify-end"},[_c('v-dialog',{attrs:{"max-width":"600","persistent":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"primary"}},'v-btn',attrs,false),on),[_vm._v(" 新增項目 "),_c('v-icon',[_vm._v("mdi-plus")])],1)]}}]),model:{value:(_vm.dialogVisible),callback:function ($$v) {_vm.dialogVisible=$$v},expression:"dialogVisible"}},[[_c('v-card',{staticClass:"p-5"},[_c('v-form',{ref:"form",attrs:{"lazy-validation":""}},[_c('DatePicker',{attrs:{"rules":_vm.emptyRule},model:{value:(_vm.formData.date),callback:function ($$v) {_vm.$set(_vm.formData, "date", $$v)},expression:"formData.date"}}),_c('v-text-field',{attrs:{"rules":_vm.emptyRule,"outlined":"","label":"標題"},model:{value:(_vm.formData.title),callback:function ($$v) {_vm.$set(_vm.formData, "title", $$v)},expression:"formData.title"}}),_c('v-textarea',{attrs:{"rules":_vm.emptyRule,"outlined":"","label":"內容"},model:{value:(_vm.formData.content),callback:function ($$v) {_vm.$set(_vm.formData, "content", $$v)},expression:"formData.content"}})],1),_c('v-card-actions',{staticClass:"justify-end"},[_c('v-btn',{attrs:{"text":""},on:{"click":function($event){return _vm.closeModal()}}},[_vm._v(" 取消 ")]),_c('v-btn',{attrs:{"color":"primary"},on:{"click":function($event){return _vm.handleSubmit()}}},[_vm._v(" 儲存 ")])],1)],1)]],2)],1),_c('v-data-table',{attrs:{"disable-sort":"","items":_vm.list,"headers":[
            { text: '日期', value: 'date' },
            { text: '標題', value: 'title' },
            { text: '內容', value: 'content' },
            { text: '', value: 'actions', sortable: false },
        ],"server-items-length":_vm.total,"options":_vm.paginationOptions,"footer-props":{
            'items-per-page-options': [5, 10, 50, 100],
        }},on:{"update:options":function($event){_vm.paginationOptions=$event}},scopedSlots:_vm._u([{key:"item.date",fn:function({ item }){return [_vm._v(" "+_vm._s(_vm._f("formatDate")(item.date))+" ")]}},{key:"item.actions",fn:function({ item }){return [_c('v-icon',{staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){return _vm.editItem(item)}}},[_vm._v(" mdi-pencil ")]),_c('v-icon',{attrs:{"small":""},on:{"click":function($event){return _vm.deleteItem(item)}}},[_vm._v(" mdi-delete ")])]}}])})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }