<template>
    <div class="w-screen h-screen flex flex-col items-center justify-center login">
        <h1 class="login-title">
            後台登入
        </h1>
        <div class="from-block">
            <v-form
                ref="form"
                v-model="formValid"
                lazy-validation
            >
                <v-text-field
                    v-model="loginForm.account"
                    :rules="emptyRule"
                    outlined
                    label="帳號"
                    @keypress.enter="login"
                />
                <v-text-field
                    v-model="loginForm.password"
                    :type="showPWD ? 'text' : 'password'"
                    :append-icon="showPWD ? 'mdi-eye' : 'mdi-eye-off'"
                    :rules="passwordRule"
                    outlined
                    label="密碼"
                    @keypress.enter="login"
                    @click:append="showPWD = !showPWD"
                />
            </v-form>
            <div class="flex justify-center">
                <v-btn
                    color="primary"
                    @click="login"
                >
                    登入
                </v-btn>
            </div>
        </div>
    </div>
</template>

<script>
import { mapActions } from 'vuex';

export default {
    components: {},
    data() {
        return {
            formValid: false,
            emptyRule: [(v) => !!v || '此欄位為必填'],
            type: 'admin',
            loginForm: {
                account: '',
                password: '',
            },
            passwordRule: [
                (v) => !!v || '此欄位為必填',
                (v) => (v && v.length >= 6) || '密碼最少6個字',
                (v) => !v.match(/\s/) || '輸入不可包含空格',
            ],
            showPWD: false,
        };
    },
    methods: {
        ...mapActions('mAuth', ['Login']),
        async login() {
            this.$refs.form.validate();
            if (this.formValid) {
                try {
                    await this.Login(this.loginForm);
                    this.$router.push('/');
                } catch (e) {
                    console.log(e);
                }
            }
        },
    },
};
</script>
<style lang="scss" scoped>
.login{
    background-color: rgba(0,0,0,0.8);

    .from-block{
        background-color: white;
        padding: 50px 80px;
        border-radius: 10px;
    }

    .login-title{
        font-size: 24px;
        margin-bottom: 20px;
        color: white
    }
}
</style>
