var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-dialog',{attrs:{"scrollable":"","max-width":"800px"},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-btn',_vm._g(_vm._b({staticClass:"mr-2",attrs:{"color":"error","small":""}},'v-btn',attrs,false),on),[_vm._v(" 刪除 ")])]}}]),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',{staticClass:"dialog-box"},[(!_vm.isDoubleCheck)?_c('v-card-title',{staticClass:"card-title"},[_vm._v(" 刪除報名資料 ")]):_vm._e(),(_vm.isDoubleCheck)?_c('v-card-text',{staticClass:"card-text"},[_c('div',{staticClass:"message"},[_vm._v(" 是否確認要刪除"),_c('br'),_vm._v(" 「 "+_vm._s(_vm.name)+" "+_vm._s(_vm.identity)+" 」報名資料？ ")]),_c('p',{staticStyle:{"text-align":"center","padding-bottom":"42px"}},[_vm._v(" 刪除原因： "+_vm._s(_vm.reason==='其他：'?_vm.other:_vm.reason)+" ")])]):_c('v-card-text',{staticClass:"card-text"},[_c('p',[_vm._v("刪除「 "+_vm._s(_vm.name)+" "+_vm._s(_vm.identity)+" 」報名資料，原因：")]),_c('v-radio-group',{attrs:{"column":""},model:{value:(_vm.reason),callback:function ($$v) {_vm.reason=$$v},expression:"reason"}},[_c('v-radio',{attrs:{"label":"已退費","value":"已退費"}}),_c('v-radio',{attrs:{"label":"未繳費，報名錯誤","value":"未繳費，報名錯誤"}}),_c('div',{staticClass:"radio-other"},[_c('v-radio',{attrs:{"label":"其他：","value":"其他："}}),_c('v-text-field',{attrs:{"label":"請輸入原因","single-line":""},model:{value:(_vm.other),callback:function ($$v) {_vm.other=$$v},expression:"other"}})],1)],1)],1),_c('v-card-actions',{staticClass:"card-btn"},[_c('v-btn',{staticClass:"button ma-auto d-block px-16",style:({
                    background: 'var(--color-primary-gradient)',
                }),attrs:{"rounded":"","dark":""},on:{"click":function($event){return _vm.confirm()}}},[_vm._v(" 確認 ")]),_c('v-btn',{staticClass:"button ma-auto d-block px-16",style:({
                    border: '3px solid #29bca2',
                    background: '#fff',
                    color: '#29bca2',
                }),attrs:{"rounded":"","dark":""},on:{"click":function($event){return _vm.cancel()}}},[_vm._v(" 取消 ")])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }