<script>
import { compress } from 'jpegasus';

export default {
    model: {
        prop: 'modelValue',
        event: 'change',
    },
    props: {
        modelValue: {
            type: String,
            required: true,
        },
        isDeleteBtn: {
            type: Boolean,
            required: false,
        },
    },
    data() {
        return {
            isShowUploadImage: false,
            isLessThan2M: false,
            oldUrl: '',
        };
    },
    computed: {
        previewUrl: {
            get() {
                return this.modelValue;
            },
            set(file) {
                this.$emit('change', file);
            },
        },
    },
    watch: {
        previewUrl(__, oldVal) {
            if (oldVal) {
                this.oldUrl = oldVal;
            }
        },
    },
    methods: {
        async handleChange(file) {
            this.isLessThan2M = file.size / 1024 / 1024 < 3;
            if (!this.isLessThan2M) this.$message.error('檔案不能超過 3MB!');
            // else this.previewUrl = URL.createObjectURL(await this.handleFiles(file));
            else this.previewUrl = URL.createObjectURL(file.raw);
        },
        async handleFiles(file) {
            const res = await compress(file.raw, {
                maxHeight: 800,
                maxWidth: 800,
                quality: 1,
                fixImageOrientation: false,
            });
            return res;
        },
    },
};
</script>

<template>
    <div class="flex items-center flex-grow-0">
        <template v-if="previewUrl || isShowUploadImage">
            <v-img
                :key="previewUrl"
                :src="$static(previewUrl)"
                lazy-src="https://4.bp.blogspot.com/-OCutvC4wPps/XfNnRz5PvhI/AAAAAAAAEfo/qJ8P1sqLWesMdOSiEoUH85s3hs_vn97HACLcBGAsYHQ/s1600/no-image-found-425x300.png"
                height="300"
                width="425"
                style="flex:none;"
            />
            <v-btn
                color="info"
                small
                class="ml-5"
                @click="previewUrl = ''"
            >
                編輯
            </v-btn>
        </template>
        <template v-else>
            <el-upload
                action=""
                accept="image/*"
                :auto-upload="false"
                :show-file-list="false"
                :on-change="handleChange"
            >
                <div class="flex flex-col">
                    <i class="el-icon-upload uploader-icon" />
                    <slot>
                        <small>只能上傳圖片</small>
                    </slot>
                </div>
            </el-upload>
            <v-btn
                color="info"
                small
                class="ml-5"
                @click="previewUrl = oldUrl"
            >
                取消
            </v-btn>
            <v-btn
                v-if="isDeleteBtn"
                color="error"
                small
                class="ml-5"
                @click="previewUrl = '';isShowUploadImage=true"
            >
                刪除
            </v-btn>
        </template>
    </div>
</template>

<style lang="scss" scoped>
.uploader-icon {
    font-size: 5em;
    color: #8c939d;
    height: 210px;
    width: 355px;
    line-height: 210px;
    text-align: center;
    display: inline-block;
    border: 1px dashed #d9d9d9;
    border-radius: 6px;

    &:hover {
        border-color: #409eff;
    }
}
</style>
