import Vue from 'vue';
import App from '@/App.vue';
import vuetify from '@/plugins/vuetify';
import router from '@/router';
import store from '@/store';
import API from '@/api';
import { Upload } from 'element-ui';
import { formatDate } from './utils/methods';

import 'element-ui/lib/theme-chalk/index.css';
import '@/scss/color.scss';
import '@/scss/icon-font.scss';
import '@/scss/reset.scss';
import '@/scss/rwd.scss';
import '@/scss/main.scss';
// eslint-disable-next-line import/no-extraneous-dependencies
import 'tailwindcss/dist/tailwind.min.css';

Vue.config.productionTip = false;

Vue.prototype.$bus = new Vue();
Vue.prototype.$bus.$on('updateToken', (token) => {
    store.dispatch('mAuth/Sync', token);
});
Vue.prototype.$static = API.Static;
store.dispatch('mAuth/Sync', localStorage.getItem('token'));

Vue.component(Upload.name, Upload);
Vue.filter('formatDate', formatDate);

require('./permission');

new Vue({
    router,
    store,
    vuetify,
    render: (h) => h(App),
}).$mount('#app');
