import API from '@/api';
import _ from 'lodash';
import * as utils from '@/store/utils';

const allowKeys = [
    'examScopeList',
    'weight',
    'activity',
    'grade',
    'subject',
    'pdfLink',
];

export default {
    namespaced: true,
    state: {},
    actions: {
        List(__, params) {
            return API.ActivityRange.PrivateList({ params }).catch(utils.handleAPIError);
        },
        async Create({ dispatch }, data) {
            return API.ActivityRange.PrivateCreateResource(
                await dispatch('convertForm', {
                    ..._.pick(data, allowKeys),
                    online: true,
                }),
            )
                .then((res) => {
                    utils.handleAPISuccess('成功');
                    return res;
                })
                .catch(utils.handleAPIError);
        },
        async Update({ dispatch }, { id, data }) {
            return API.ActivityRange.PrivateUpdateResource(
                id,
                await dispatch('convertForm', {
                    ..._.pick(data, allowKeys),
                    online: true,
                }),
            )
                .then(() => utils.handleAPISuccess('成功'))
                .catch(utils.handleAPIError);
        },
        Delete(__, id) {
            return API.ActivityRange.PrivateDeleteResource(id)
                .then(() => utils.handleAPISuccess('成功'))
                .catch(utils.handleAPIError);
        },
        convertForm(__, form) {
            const data = _.cloneDeep(form);
            return new API.FormData(data, [
                {
                    urls: [data.pdfLink],
                    ext: (mimetype) => ({
                        'image/png': 'png',
                        'image/jpeg': 'jpg',
                        'application/pdf': 'pdf',
                    }[mimetype] || 'png'),
                },
            ]);
        },
    },
};
