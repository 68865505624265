<template>
    <div class="history-page grid gap-2">
        <div class="flex items-center justify-between pt-2">
            <div>
                <v-select
                    v-model="currentActivity"
                    :items="[{text:'全部',value:''},...activityOptions]"
                    item-text="text"
                    item-value="value"
                    label="選擇活動"
                    outlined
                    style="width: 500px;"
                    @change="getList()"
                />
            </div>

            <v-dialog
                v-model="dialogVisible"
                max-width="600"
                persistent
            >
                <template v-slot:activator="{ on, attrs }">
                    <v-btn
                        v-bind="attrs"
                        color="primary"
                        v-on="on"
                        @click="formData.id = ''"
                    >
                        新增項目
                        <v-icon>mdi-plus</v-icon>
                    </v-btn>
                </template>
                <template>
                    <v-card class="p-5">
                        <v-form
                            ref="form"
                            lazy-validation
                        >
                            <v-select
                                v-model="formData.activity"
                                :items="activityOptions"
                                :rules="emptyRule"
                                item-text="text"
                                item-value="value"
                                label="選擇活動"
                                outlined
                            />
                            <v-select
                                v-model="formData.grade"
                                :items="[
                                    '一年級',
                                    '二年級',
                                    '三年級',
                                    '四年級',
                                    '五年級',
                                    '六年級',
                                ]"
                                :rules="emptyRule"
                                label="選擇年級"
                                outlined
                            />
                            <v-select
                                v-model="formData.subject"
                                :items="['國文', '英文', '數學', '自然', '智力測驗']"
                                :rules="emptyRule"
                                label="選擇科目"
                                outlined
                            />
                            <v-text-field
                                v-model="formData.weight"
                                :rules="emptyRule"
                                outlined
                                label="排序權重欄位"
                            />
                            <FileUploader
                                v-model="formData.pdfLink"
                                :rules="emptyRule"
                                label="檔案（PDF）"
                                accept=".pdf"
                            />
                        </v-form>
                        <v-card-actions class="justify-end">
                            <v-btn
                                text
                                @click="closeModal()"
                            >
                                取消
                            </v-btn>
                            <v-btn
                                color="primary"
                                @click="handleSubmit()"
                            >
                                儲存
                            </v-btn>
                        </v-card-actions>
                    </v-card>
                </template>
            </v-dialog>
        </div>
        <div>
            <v-btn-toggle
                v-model="currentTab"
                @change="getList()"
            >
                <template v-for="item in menu">
                    <v-btn
                        :key="item.text"
                        :value="item.value"
                    >
                        {{ item.text }}
                    </v-btn>
                </template>
            </v-btn-toggle>
        </div>
        <v-data-table
            disable-sort
            :items="list"
            :headers="[
                { text: '活動', value: 'activity' },
                { text: '年級', value: 'grade' },
                { text: '科目', value: 'subject' },
                { text: '權重', value: 'weight' },
                { text: '', value: 'actions', sortable: false },
            ]"
            :server-items-length="total"
            :options.sync="paginationOptions"
            :footer-props="{
                'items-per-page-options': [5, 10, 50, 100],
            }"
        >
            <template v-slot:item.actions="{ item }">
                <v-icon
                    small
                    class="mr-2"
                    @click="editItem(item)"
                >
                    mdi-pencil
                </v-icon>
                <v-icon
                    small
                    @click="deleteItem(item)"
                >
                    mdi-delete
                </v-icon>
            </template>
        </v-data-table>
    </div>
</template>

<script>
import { mapActions } from 'vuex';
import { MessageBox } from 'element-ui';
import _ from 'lodash';
import FileUploader from '@/components/FileUploader.vue';

const initData = () => ({
    id: '',
    activity: '',
    grade: '',
    subject: '',
    pdfLink: '',
    weight: 1,
});

export default {
    components: {
        FileUploader,
    },
    data() {
        return {
            currentActivity: '',
            currentTab: '',
            menu: [
                { text: '全部', value: '' },
                { text: '一年級', value: '一年級' },
                { text: '二年級', value: '二年級' },
                { text: '三年級', value: '三年級' },
                { text: '四年級', value: '四年級' },
                { text: '五年級', value: '五年級' },
                { text: '六年級', value: '六年級' },
            ],
            dialogVisible: false,
            formData: initData(),
            emptyRule: [(v) => !!v || '此欄位為必填'],
            paginationOptions: {
                page: 1,
                itemsPerPage: 10,
            },
            total: 0,
            list: [],
            activityOptions: [],
        };
    },
    watch: {
        paginationOptions: {
            handler() {
                this.getList();
            },
            deep: true,
        },
    },
    created() {
        this.getList();
        this.getActivityLists();
    },
    methods: {
        ...mapActions('mActivity', {
            activityList: 'List',
        }),
        ...mapActions('mExam', ['List', 'Create', 'Delete', 'Update']),
        getActivityLists() {
            this.activityList().then((res) => {
                const data = res.data.raw;
                this.activityOptions = data.map((item) => item.name);
            });
        },
        getFilter() {
            if (this.currentActivity && this.currentTab) {
                return `grade:${this.currentTab};activity:${this.currentActivity}`;
            }
            if (this.currentActivity) {
                return `activity:${this.currentActivity}`;
            } if (this.currentTab) {
                return `grade:${this.currentTab}`;
            }
            return '';
        },
        getList() {
            const { page, itemsPerPage } = this.paginationOptions;
            this.List({
                offset: (page - 1) * itemsPerPage,
                limit: itemsPerPage,
                filter: this.getFilter(),
            }).then((res) => {
                this.list = res.data.raw;
                this.total = res.data.total;
            });
        },
        clearForm() {
            this.formData = initData();
            setTimeout(this.$refs.form.reset, 100);
        },
        closeModal() {
            this.clearForm();
            this.dialogVisible = false;
        },
        handleSubmit() {
            if (this.$refs.form.validate()) {
                if (this.formData.id) {
                    this.Update({ id: this.formData.id, data: this.formData })
                        .then(this.getList)
                        .then(this.closeModal);
                } else {
                    this.Create(this.formData).then(this.getList).then(this.closeModal);
                }
            }
        },
        editItem(item) {
            this.formData.id = item.id;
            _.assign(this.formData, item);
            this.dialogVisible = true;
        },
        deleteItem(item) {
            MessageBox.confirm(item.title, {
                type: 'warning',
                title: '確認刪除？',
                confirmButtonText: '刪除',
                cancelButtonText: '取消',
            })
                .then(() => {
                    this.Delete(item.id).then(this.getList);
                })
                .catch(() => {});
        },
    },
};
</script>
<style>
.history-page .v-text-field__details{
    display: none;
}
</style>
