import API from '@/api';
import _ from 'lodash';
import * as utils from '@/store/utils';

const allowKeys = [
    'place',
    'name',
    'gender',
    'identity',
    'birthYear',
    'birthMonth',
    'birthDay',
    'city',
    'school',
    'grade',
    'class',
    'parentName',
    'postalCode',
    'address',
    'cellphone',
    // 'groupCellphone',
    'telephone',
    'remark',
];

export default {
    namespaced: true,
    state: {},
    actions: {
        Fetch(__, id) {
            return API.Apply.PrivateFetchResource(id).catch(utils.handleAPIError);
        },
        FetchView(__, id) {
            return API.Apply.PrivateFetchResource(id, {
                params: {
                    stage: 'deleted',
                },
            }).catch(utils.handleAPIError);
        },
        List(__, params) {
            return API.Apply.PrivateList({ params }).catch(utils.handleAPIError);
        },
        async Create(__, { competition, data }) {
            return API.Apply.PrivateCreateResource(competition, _.pick(data, allowKeys))
                .then((res) => {
                    utils.handleAPISuccess('成功');
                    return res;
                })
                .catch(utils.handleAPIError);
        },
        async Update(__, { id, data }) {
            console.log();
            return API.Apply.PrivateUpdateResource(id, _.pick(data, allowKeys))
                .then(() => utils.handleAPISuccess('成功'))
                .catch(utils.handleAPIError);
        },
        Delete(__, id) {
            return API.Apply.PrivateDeleteResource(id)
                .then(() => utils.handleAPISuccess('成功'))
                .catch(utils.handleAPIError);
        },
        convertForm(__, form) {
            const data = _.cloneDeep(form);
            return new API.FormData(data, [
                {
                    urls: [data.pdfLink],
                    ext: (mimetype) => ({
                        'image/png': 'png',
                        'image/jpeg': 'jpg',
                        'application/pdf': 'pdf',
                    }[mimetype] || 'png'),
                },
            ]);
        },
        Download(__, activityId) {
            return API.ApplyUtil.Download(activityId).catch(utils.handleAPIError);
        },
        Upload(__, { activityId, file }) {
            return API.ApplyUtil.Upload(activityId, file)
                .then(() => utils.handleAPISuccess('成功'))
                .catch(utils.handleAPIError);
        },
        UpdatePayStatus(__, { id, status }) {
            return API.ApplyUtil.UpdatePayStatus(id, status)
                .then(() => utils.handleAPISuccess('成功'))
                .catch(utils.handleAPIError);
        },

    },
};
