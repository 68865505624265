import NProgress from 'nprogress';
import 'nprogress/nprogress.css';
//
import router from './router';
import store from './store';
//

NProgress.configure({ showSpinner: false, speed: 500 });
router.beforeEach((to, __, next) => {
    NProgress.start();
    NProgress.inc(0.5);

    if (store.getters['mAuth/isLogin']) {
        if (to.name === 'Login') next('/');
        next();
    } else if (!to.matched.some(({ meta }) => meta.auth)) {
        next();
    } else {
        next({ name: 'Login' });
    }
});

router.afterEach(() => {
    setTimeout(NProgress.done, 500);
});
