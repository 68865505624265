/* eslint-disable max-len */
import _ from 'lodash';
import axios from 'axios';
import { v4 as uuid4 } from 'uuid';
import APIFormData from '@/api/libs/form-data';

export default (getOptions) => ({
    Create: async (competition, data) => {
        const body = await APIFormData.toBody(data);
        return axios.post(`/api/apply/pub/create/${competition}`, body, getOptions());
    },
    Download: async (activity) => axios.get(`/api/apply/pvt/download/${activity}`, _.merge(getOptions(), {
        responseType: 'blob',
    })),
    Upload: async (activity, blobUrl) => {
        const form = new FormData();
        try {
            const res = await axios.get(blobUrl, { responseType: 'blob' });
            form.append('file', res.data, `${uuid4().slice(-12)}.xlsx`);
        } catch (e) { throw new Error(`not found blob url (${blobUrl})`); }
        return axios.post(`/api/apply/pvt/upload/${activity}`, form, getOptions());
    },
    PersonalQuery: async (activity, data) => axios.get(`/api/apply/pub/personal/query/${activity}`, _.merge(getOptions(), {
        params: data,
    })),
    GroupQuery: async (activity, data, options) => axios.get(`/api/apply/pub/group/query/${activity}`, _.merge(getOptions(), options, {
        params: data,
    })),
    HistoryQuery: async (data, options) => axios.get('/api/apply/pub/history/query', _.merge(getOptions(), options, {
        params: data,
    })),
    ScoreQuery: async (activity, data) => axios.get(`/api/apply/pub/score/query/${activity}`, _.merge(getOptions(), {
        params: data,
    })),
    CertificationQuery: async (activity, data) => axios.get(`/api/apply/pub/certification/query/${activity}`, _.merge(getOptions(), {
        params: data,
        responseType: 'blob',
    })),
    GenerateMpg: async (serial, options) => axios.post(`/api/newebpay/pub/mpg/${serial}`, options, getOptions()),
    UpdatePayStatus: async (id, target) => axios.put(`/api/apply/pvt/${id}/pay/status`, { target }, getOptions()),
    SoftDelete: async (id, reason) => axios.put(`/api/apply/pvt/soft-delete/${id}`, { reason }, getOptions()),
    ChangeCompetition: async (id, data) => {
        const { competition, identity, place } = data;
        return axios.put(`/api/apply/pub/change/${id}/competition/${competition}`, { place }, _.merge(getOptions(), {
            params: { identity },
        }));
    },
});
