<template>
    <div class="grid gap-2">
        <div class="flex justify-end">
            <v-btn
                color="primary"
                to="/activity/create"
            >
                新增活動
                <v-icon>mdi-plus</v-icon>
            </v-btn>
        </div>
        <v-data-table
            disable-sort
            :items="list"
            :headers="[
                { text: '名稱', value: 'name' },
                {
                    text: '',
                    value: 'edit',
                    align: 'center',
                    sortable: false,
                    width: 100,
                    divider: true,
                },
                {
                    text: '設定活動',
                    value: 'setting',
                    align: 'center',
                    sortable: false,
                    width: 100,
                    divider: true,
                },
                {
                    text: '選擇當前活動',
                    value: 'select',
                    align: 'center',
                    sortable: false,
                    width: 130,
                    divider: true,
                },
                {
                    text: '',
                    value: 'delete',
                    align: 'center',
                    sortable: false,
                    width: 100,
                },
            ]"
            :server-items-length="total"
            :options.sync="paginationOptions"
            :footer-props="{
                'items-per-page-options': [5, 10, 50, 100],
            }"
        >
            <template v-slot:item.edit="{ item }">
                <v-btn :to="`/activity/${item.id}`">
                    編輯
                </v-btn>
            </template>
            <template v-slot:item.setting="{ item }">
                <v-btn
                    :to="`/competition/${item.id}`"
                    color="#444444"
                    dark
                >
                    設定
                </v-btn>
            </template>
            <template v-slot:item.select="{ item }">
                <v-btn
                    text
                    :disabled="active(item)"
                    @click="changeCurrent(item)"
                >
                    {{ active(item) ? "前台顯示中" : "設為當前活動" }}
                </v-btn>
            </template>
            <template v-slot:item.delete="{ item }">
                <v-btn
                    icon
                    text
                    @click="deleteItem(item)"
                >
                    <v-icon small>
                        mdi-delete
                    </v-icon>
                </v-btn>
            </template>
        </v-data-table>
    </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import { MessageBox } from 'element-ui';

export default {
    data() {
        return {
            paginationOptions: {
                page: 1,
                itemsPerPage: 10,
            },
            total: 0,
            list: [],
        };
    },
    computed: {
        ...mapState('mSetting', ['settings']),
    },
    watch: {
        paginationOptions: {
            handler() {
                this.getList();
            },
            deep: true,
        },
    },
    created() {
        this['mSetting/PublicInfo']();
        this.getList();
    },
    methods: {
        ...mapActions('mActivity', ['List', 'Delete', 'Update']),
        ...mapActions(['mSetting/Update', 'mSetting/PublicInfo']),
        active(item) {
            return !!this.settings['當前活動'] && this.settings['當前活動'] === item.id;
        },
        getList() {
            const { page, itemsPerPage } = this.paginationOptions;
            this.List({
                offset: (page - 1) * itemsPerPage,
                limit: itemsPerPage,
            }).then((res) => {
                this.list = res.data.raw;
                this.total = res.data.total;
            });
        },
        deleteItem(item) {
            MessageBox.confirm(item.title, {
                type: 'warning',
                title: '確認刪除？',
                confirmButtonText: '刪除',
                cancelButtonText: '取消',
            })
                .then(() => {
                    this.Delete(item.id).then(this.getList);
                })
                .catch(() => {});
        },
        changeCurrent(item) {
            MessageBox.confirm(item.title, {
                type: 'warning',
                title: '確認修改當前活動？',
                confirmButtonText: '確定修改',
                cancelButtonText: '取消',
            })
                .then(() => {
                    this['mSetting/Update']({ key: '當前活動', value: item.id })
                        .then(this['mSetting/PublicInfo']);
                })
                .catch(() => {});
        },
    },
};
</script>
