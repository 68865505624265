import _ from 'lodash';
import axios from 'axios';

export default class SettingAPI {
    constructor(name, getDefaultOptions) {
        this.name = name;
        this.getDefaultOptions = getDefaultOptions;
    }

    toConfig(options) {
        return _.chain(this.getDefaultOptions())
            .merge(options)
            .merge({
                headers: {
                    'content-type': 'application/json',
                },
            })
            .value();
    }

    async PublicInfo(options) {
        return axios.get(`/api/${this.name}/pub/info`, this.toConfig(options));
    }

    async List(options) {
        return axios.get(`/api/${this.name}/pvt/info/list`, this.toConfig(options));
    }

    async Update(key, value, options) {
        console.log(value);
        return axios.put(`/api/${this.name}/pvt/update/${key}`, { value }, this.toConfig(options));
    }

    async Updates(list, options) {
        return axios.put(`/api/${this.name}/pvt/updates`, { list }, this.toConfig(options));
    }
}
