<template>
    <ul class="recursive-menu">
        <template v-for="item in data">
            <RecursiveMenuItem
                :key="item.title"
                :data="item"
                class="recursive-menu-item-root"
            >
                <!-- <template #title>
                    <strong>{{ item.title }}</strong>
                </template> -->
            </RecursiveMenuItem>
        </template>
    </ul>
</template>

<script>
import RecursiveMenuItem from '@/components/RecursiveMenuItem.vue';

export default {
    components: {
        RecursiveMenuItem,
    },
    props: {
        data: {
            type: Array,
            required: true,
        },
    },
};
</script>

<style lang="scss" scoped>
ul {
    padding-left: 1em !important;
    list-style: none;
}
</style>
