/* eslint-disable max-len */
/* eslint-disable no-console */
import Vue from 'vue';
import _ from 'lodash';
import moment from 'moment';
import axios from 'axios';
import jwt from 'jsonwebtoken';
import APIFormData from '@/api/libs/form-data';
import RequestCache from '@/api/libs/request-cache';

export default class UserAPI {
    constructor(name, getDefaultOptions) {
        this.name = name;
        this.getDefaultOptions = getDefaultOptions;
        this.cache = new RequestCache(this.name, this.SubKeys);
    }

    // eslint-disable-next-line class-methods-use-this
    get SubKeys() {
        return {};
    }

    toConfig(options) {
        return _.merge(this.getDefaultOptions(), options);
    }

    // eslint-disable-next-line class-methods-use-this
    saveToken(res) {
        const token = res.data?.token;
        if (token) {
            // eslint-disable-next-line no-undef
            localStorage.setItem('role', this.name);
            // eslint-disable-next-line no-undef
            localStorage.setItem('token', token);
        }
        // eslint-disable-next-line no-unused-expressions
        Vue.prototype.$bus?.$emit('updateToken', token);
    }

    async Create(data, options) {
        const body = await APIFormData.toBody(data);
        return axios.post(`/api/${this.name}/create`, body, this.toConfig(options));
    }

    async Register(data, options) {
        const body = await APIFormData.toBody(data);
        const res = await axios.post(`/api/${this.name}/register`, body, this.toConfig(options));
        if (_.get(options, 'autoSaveToken', true)) {
            this.saveToken(res);
        }
        return res;
    }

    async ConfirmRegisterOtp(data, options) {
        const body = await APIFormData.toBody(data);
        const res = await axios.post(`/api/${this.name}/register/otp/confirm`, body, this.toConfig(options));
        if (_.get(options, 'autoSaveToken', true)) {
            this.saveToken(res);
        }
        return res;
    }

    async SendRegisterOtp(data, options) {
        const body = await APIFormData.toBody(data);
        return axios.post(`/api/${this.name}/register/otp/send`, body, this.toConfig(options));
    }

    async ChangeRegisterOtpToToken(data, options) {
        const body = await APIFormData.toBody(data);
        return axios.post(`/api/${this.name}/register/otp/change/token`, body, this.toConfig(options));
    }

    async Login(data, options) {
        const body = await APIFormData.toBody(data);
        const res = await axios.post(`/api/${this.name}/login`, body, this.toConfig(options));
        this.saveToken(res);
        return res;
    }

    async LoginWithSocial(type, data, options) {
        const body = await APIFormData.toBody(data);
        const res = await axios.post(`/api/${this.name}/login/social/${type}`, body, this.toConfig(options));
        if (!res.data?.register) {
            this.saveToken(res);
        }
        return res;
    }

    async UpdateLoginSocial(type, data, options) {
        const body = await APIFormData.toBody(data);
        const res = await axios.patch(`/api/${this.name}/login/social/${type}`, body, this.toConfig(options));
        this.saveToken(res);
        return res;
    }

    async RefreshToken(options) {
        const res = await axios.post(`/api/${this.name}/token/refresh`, {}, this.toConfig(options));
        this.saveToken(res);
        return res;
    }

    async AutoRefreshToken() {
        // eslint-disable-next-line no-undef
        const role = localStorage.getItem('role');
        // eslint-disable-next-line no-undef
        const token = localStorage.getItem('token');
        if (token && role === this.name) {
            const user = jwt.decode(token);
            const expiredTime = user.exp * 1000;
            const immediateExpiredTime = (user.iat + (user.exp - user.iat) * 0.1) * 1000; // 超過 10% 的效期
            console.log('token expired time', moment(expiredTime).format('YYYY/MM/DD HH:mm:ss'));
            console.log('immediate expire time', moment(immediateExpiredTime).format('YYYY/MM/DD HH:mm:ss'));
            const isNeedRefresh = _.every([
                !!user,
                Date.now() < expiredTime,
                Date.now() >= immediateExpiredTime,
            ]);
            if (isNeedRefresh) {
                await this.RefreshToken();
            }
        }
    }

    async SendForgotPasswordOtp(data, options) {
        const body = await APIFormData.toBody(data);
        return axios.post(`/api/${this.name}/password/forgot/otp/send`, body, this.toConfig(options));
    }

    async ChangeForgotPasswordOtpToToken(data, options) {
        const body = await APIFormData.toBody(data);
        return axios.post(`/api/${this.name}/password/forgot/otp/change/token`, body, this.toConfig(options));
    }

    async ResetPassword(token, data) {
        const body = await APIFormData.toBody(data);
        return axios.post(`/api/${this.name}/password/reset`, body, this.toConfig({
            headers: {
                authorization: `Bearer ${token}`,
            },
        }));
    }

    async ChangePassword(data, options) {
        const body = await APIFormData.toBody(data);
        return axios.post(`/api/${this.name}/password/change`, body, this.toConfig(options));
    }

    async UpdatePassword(data, options) {
        const body = await APIFormData.toBody(data);
        return axios.post(`/api/${this.name}/password/update`, body, this.toConfig(options));
    }

    async FetchInfo(options) {
        return axios.get(`/api/${this.name}/info`, this.toConfig(options));
    }

    async UpdateInfo(data, options) {
        this.cache.clean();
        //
        const body = await APIFormData.toBody(data);
        const res = await axios.put(`/api/${this.name}/info`, body, this.toConfig(options));
        this.saveToken(res);
        return res;
    }
}
