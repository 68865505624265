<template>
    <div class="grid gap-1 max-w-xl">
        <p>{{ id && id !== 'create' ? '編輯' : '創建' }}活動</p>
        <v-form
            ref="form"
            lazy-validation
        >
            <table
                class="border-separate"
                style="border-spacing: 20px"
            >
                <tr>
                    <th class="whitespace-nowrap text-left">
                        <p class="mb-7">
                            項目命名
                        </p>
                    </th>
                    <td>
                        <v-text-field
                            v-model="formData.name"
                            :rules="emptyRule"
                            outlined
                            label="項目命名"
                        />
                    </td>
                </tr>
                <tr>
                    <th class="whitespace-nowrap text-left">
                        <p class="mb-7">
                            管理 活動辦法名稱
                        </p>
                    </th>
                    <td>
                        <v-text-field
                            v-model="formData.title"
                            :rules="emptyRule"
                            outlined
                            label="活動辦法名稱"
                        />
                    </td>
                </tr>
                <tr>
                    <th class="whitespace-nowrap text-left">
                        <p>報名表單開放日期</p>
                    </th>
                    <td>
                        <div class="flex items-center gap-2">
                            <DatePicker
                                v-model="formData.applyStart"
                                :rules="emptyRule"
                                label="開始"
                            />
                            ～
                            <DatePicker
                                v-model="formData.applyEnd"
                                :rules="emptyRule"
                                label="結束"
                            />
                        </div>
                    </td>
                </tr>
                <tr>
                    <th class="whitespace-nowrap text-left">
                        <p>繳費截止日期</p>
                    </th>
                    <td>
                        <DatePicker
                            v-model="formData.payDeadline"
                            :rules="emptyRule"
                            label="繳費截止日期"
                        />
                    </td>
                </tr>
                <tr>
                    <th class="whitespace-nowrap text-left">
                        <p>開放上網查詢成績</p>
                    </th>
                    <td>
                        <DatePicker
                            v-model="formData.publicResultTime"
                            :rules="emptyRule"
                            label="開放上網查詢成績"
                        />
                    </td>
                </tr>
            </table>
            <v-divider class="mb-5" />
        </v-form>
        <div class="flex justify-center">
            <v-btn
                v-if="id && id !== 'create'"
                text
                color="error"
                @click="handleDelete()"
            >
                刪除
            </v-btn>
            <v-btn
                text
                @click="$router.push('/activity')"
            >
                返回
            </v-btn>
            <v-btn
                color="primary"
                class="ml-2"
                @click="handleSubmit()"
            >
                儲存
            </v-btn>
        </div>
    </div>
</template>

<script>
import { mapActions } from 'vuex';
import { MessageBox } from 'element-ui';
import DatePicker from '@/components/VDatePicker.vue';
import _ from 'lodash';

const initData = () => ({
    name: '',
    title: '',
    applyStart: '',
    applyEnd: '',
    payDeadline: '',
    publicResultTime: '',
});

export default {
    components: {
        DatePicker,
    },
    props: {
        id: {
            type: String,
            default: () => 'create',
        },
    },
    data() {
        return {
            formData: initData(),
            emptyRule: [(v) => !!v || '此欄位為必填'],
        };
    },
    created() {
        if (this.id !== 'create') {
            this.Fetch(this.id).then((res) => {
                _.assign(this.formData, res.data);
            });
        }
    },
    methods: {
        ...mapActions('mActivity', ['Fetch', 'Create', 'Delete', 'Update']),
        clearForm() {
            this.formData = initData();
            setTimeout(this.$refs.form.reset, 100);
        },
        handleSubmit() {
            if (this.$refs.form.validate()) {
                if (this.id && this.id !== 'create') {
                    this.Update({ id: this.id, data: this.formData });
                } else {
                    this.Create(this.formData).then((res) => {
                        this.$router.push(`/activity/${res.data.id}`);
                    });
                }
            }
        },
        handleDelete() {
            MessageBox.confirm(this.formData.name, {
                type: 'warning',
                title: '確認刪除？',
                confirmButtonText: '刪除',
                cancelButtonText: '取消',
            })
                .then(() => {
                    this.Delete(this.id).then(() => this.$router.push('/activity'));
                })
                .catch(() => {});
        },
    },
};
</script>
