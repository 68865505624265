<template>
    <div class="grid gap-1">
        <p>退費辦法/活動須知</p>

        <v-form
            ref="form"
            lazy-validation
        >
            <template v-for="(val, key) in formData">
                <v-textarea
                    :key="key"
                    v-model="formData[key]"
                    :rules="emptyRule"
                    :label="key"
                    outlined
                />
            </template>
        </v-form>
        <div class="flex justify-center">
            <v-btn
                color="primary"
                @click="handleSubmit()"
            >
                確認修改
            </v-btn>
        </div>
    </div>
</template>

<script>
import { mapActions } from 'vuex';

export default {
    data() {
        return {
            formData: {
                退費辦法: '',
                說明事項: '',
                應試規則: '',
            },
            emptyRule: [(v) => !!v || '此欄位為必填'],
        };
    },
    created() {
        this.getList();
    },
    methods: {
        ...mapActions('mSetting', ['List', 'UpdateMultiple']),
        getList() {
            this.List().then((res) => {
                this.formData = res.data?.reduce((obj, current) => {
                    if (['退費辦法', '說明事項', '應試規則'].includes(current.key)) {
                        // eslint-disable-next-line no-param-reassign
                        obj[current.key] = current.value;
                    }
                    return obj;
                }, {});
            });
        },
        handleSubmit() {
            if (this.$refs.form.validate()) {
                this.UpdateMultiple(
                    Object.keys(this.formData).map((key) => ({
                        key,
                        value: this.formData[key],
                    })),
                );
            }
        },
    },
};
</script>
