import Vue from 'vue';
import VueRouter from 'vue-router';
import Layer1 from '@/layouts/Layer1.vue';
import Layer2 from '@/layouts/Layer2.vue';
import Login from '@/views/Login.vue';
import Home from '@/views/Home.vue';
import Schedule from '@/views/Schedule.vue';
import Question from '@/views/Question.vue';
import Setting from '@/views/Setting.vue';
import Activities from '@/views/Activities.vue';
import Activity from '@/views/Activity.vue';
import CompetitionItem from '@/views/CompetitionItem.vue';
import CompetitionActivity from '@/views/CompetitionActivity.vue';
import CompetitionScope from '@/views/CompetitionScope.vue';
import CompetitionResult from '@/views/CompetitionResult.vue';
import CompetitionApply from '@/views/CompetitionApply.vue';
import CompetitionApplyItem from '@/views/CompetitionApplyItem.vue';
import CompetitionApplyItemView from '@/views/CompetitionApplyItemView.vue';
import CompetitionCertificate from '@/views/CompetitionCertificate.vue';
import Historys from '@/views/Historys.vue';
// import Page404 from '@/views/404.vue';

Vue.use(VueRouter);

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    scrollBehavior() {
        return { x: 0, y: 0 };
    },
    routes: [
        {
            path: '/login',
            name: 'Login',
            component: Login,
        },
        {
            path: '/',
            component: Layer1,
            redirect: 'home',
            meta: { auth: true },
            children: [
                {
                    path: 'home',
                    name: 'Home',
                    component: Home,
                    meta: { title: '管理 首頁輪播圖' },
                },
                {
                    path: 'schedule',
                    name: 'Schedule',
                    component: Schedule,
                    meta: { title: '管理 NEST考試日程' },
                },
                {
                    path: 'question',
                    name: 'Question',
                    component: Question,
                    meta: { title: '管理 常見問題' },
                },
                {
                    path: 'setting',
                    name: 'Setting',
                    component: Setting,
                    meta: { title: '管理 退費辦法/活動須知' },
                },
                {
                    path: 'activity',
                    name: 'Activities',
                    component: Activities,
                    meta: { title: '創建活動' },
                },
                {
                    path: 'activity/:id',
                    name: 'Activity',
                    component: Activity,
                    props: true,
                    meta: { title: '創建活動' },
                },
                {
                    path: 'history',
                    name: 'Historys',
                    component: Historys,
                    meta: { title: '設置 歷屆試題' },
                },
            ],
        },
        {
            path: '/competition/:activityId',
            component: Layer2,
            props: true,
            children: [
                {
                    path: '',
                    redirect: 'item',
                },
                {
                    path: 'item',
                    name: 'CompetitionItem',
                    component: CompetitionItem,
                    props: true,
                    meta: { title: '設置 競賽項目' },
                },
                {
                    path: 'activity',
                    name: 'CompetitionActivity',
                    component: CompetitionActivity,
                    props: true,
                    meta: { title: '管理 活動辦法' },
                },
                {
                    path: 'scope',
                    name: 'CompetitionScope',
                    component: CompetitionScope,
                    props: true,
                    meta: { title: '管理 考試範圍' },
                },
                {
                    path: 'apply',
                    name: 'CompetitionApply',
                    component: CompetitionApply,
                    props: true,
                    meta: { title: '管理 報名資料' },
                },
                {
                    path: 'apply/:id',
                    name: 'CompetitionApplyItem',
                    component: CompetitionApplyItem,
                    props: true,
                    meta: { title: '管理 報名資料' },
                },
                {
                    path: 'apply/view/:id',
                    name: 'CompetitionApplyItemView',
                    component: CompetitionApplyItemView,
                    props: true,
                    meta: { title: '管理 報名資料' },
                },
                {
                    path: 'certificate',
                    name: 'CompetitionCertificate',
                    component: CompetitionCertificate,
                    props: true,
                    meta: { title: '上傳 獎狀及證書' },
                },
                {
                    path: 'result',
                    name: 'CompetitionResult',
                    component: CompetitionResult,
                    props: true,
                    meta: { title: '管理 整體分數統計數據' },
                },
                {
                    path: '*',
                    redirect: 'item',
                },
            ],
        },
        {
            path: '*',
            redirect: 'home',
        },
    ],
});

export default router;
