<template>
    <div class="grid gap-2">
        <div class="flex justify-end">
            <v-dialog
                v-model="dialogVisible"
                max-width="600"
                persistent
            >
                <template v-slot:activator="{ on, attrs }">
                    <v-btn
                        v-bind="attrs"
                        color="primary"
                        v-on="on"
                    >
                        新增項目
                        <v-icon>mdi-plus</v-icon>
                    </v-btn>
                </template>
                <template>
                    <v-card class="p-5">
                        <v-form
                            ref="form"
                            lazy-validation
                        >
                            <DatePicker
                                v-model="formData.date"
                                :rules="emptyRule"
                            />
                            <v-text-field
                                v-model="formData.title"
                                :rules="emptyRule"
                                outlined
                                label="標題"
                            />
                            <v-textarea
                                v-model="formData.content"
                                :rules="emptyRule"
                                outlined
                                label="內容"
                            />
                        </v-form>
                        <v-card-actions class="justify-end">
                            <v-btn
                                text
                                @click="closeModal()"
                            >
                                取消
                            </v-btn>
                            <v-btn
                                color="primary"
                                @click="handleSubmit()"
                            >
                                儲存
                            </v-btn>
                        </v-card-actions>
                    </v-card>
                </template>
            </v-dialog>
        </div>
        <v-data-table
            disable-sort
            :items="list"
            :headers="[
                { text: '日期', value: 'date' },
                { text: '標題', value: 'title' },
                { text: '內容', value: 'content' },
                { text: '', value: 'actions', sortable: false },
            ]"
            :server-items-length="total"
            :options.sync="paginationOptions"
            :footer-props="{
                'items-per-page-options': [5, 10, 50, 100],
            }"
        >
            <template v-slot:item.date="{ item }">
                {{ item.date | formatDate }}
            </template>
            <template v-slot:item.actions="{ item }">
                <v-icon
                    small
                    class="mr-2"
                    @click="editItem(item)"
                >
                    mdi-pencil
                </v-icon>
                <v-icon
                    small
                    @click="deleteItem(item)"
                >
                    mdi-delete
                </v-icon>
            </template>
        </v-data-table>
    </div>
</template>

<script>
import { mapActions } from 'vuex';
import { MessageBox } from 'element-ui';
import _ from 'lodash';
import DatePicker from '@/components/VDatePicker.vue';

const initData = () => ({
    id: '',
    date: '',
    title: '',
    content: '',
});
export default {
    components: {
        DatePicker,
    },
    data() {
        return {
            dialogVisible: false,
            formData: initData(),
            emptyRule: [(v) => !!v || '此欄位為必填'],
            paginationOptions: {
                page: 1,
                itemsPerPage: 10,
            },
            total: 0,
            list: [],
        };
    },
    watch: {
        paginationOptions: {
            handler() {
                this.getList();
            },
            deep: true,
        },
    },
    created() {
        this.getList();
    },
    methods: {
        ...mapActions('mSchedule', ['List', 'Create', 'Delete', 'Update']),
        getList() {
            const { page, itemsPerPage } = this.paginationOptions;
            this.List({
                offset: (page - 1) * itemsPerPage,
                limit: itemsPerPage,
            }).then((res) => {
                this.list = res.data.raw;
                this.total = res.data.total;
            });
        },
        clearForm() {
            this.formData = initData();
            setTimeout(this.$refs.form.reset, 100);
        },
        closeModal() {
            this.clearForm();
            this.dialogVisible = false;
        },
        handleSubmit() {
            if (this.$refs.form.validate()) {
                if (this.formData.id) {
                    this.Update(this.formData)
                        .then(this.getList)
                        .then(this.closeModal);
                } else {
                    this.Create(this.formData)
                        .then(this.getList)
                        .then(this.closeModal);
                }
            }
        },
        editItem(item) {
            _.assign(this.formData, item);
            this.dialogVisible = true;
        },
        deleteItem(item) {
            MessageBox.confirm(item.title, {
                type: 'warning',
                title: '確認刪除？',
                confirmButtonText: '刪除',
                cancelButtonText: '取消',
            })
                .then(() => {
                    this.Delete(item.id).then(this.getList);
                })
                .catch(() => { });
        },
    },
};
</script>
