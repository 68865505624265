<template>
    <div class="grid gap-2 max-w-xl">
        <v-form
            ref="form"
            lazy-validation
        >
            <v-textarea
                v-model="formData.qualification"
                :rules="emptyRule"
                outlined
                label="參賽資格"
            />
            <v-divider class="my-5" />
            <v-textarea
                v-model="formData.fee"
                :rules="emptyRule"
                outlined
                label="報名費用"
            />
            <v-divider class="my-5" />
            <strong class="mb-2 block">報名贈品</strong>
            <ImageUploader
                v-model="formData.giveaway.thumb"
                :is-delete-btn="true"
            />
            <br>
            <v-text-field
                v-model="formData.giveaway.title"
                outlined
                label="標題"
            />
            <v-textarea
                v-model="formData.giveaway.description"
                outlined
                label="內容"
            />
            <v-divider class="my-5" />
            <strong class="mb-2 block">獎項說明</strong>
            <ImageUploader v-model="formData.prize.thumb" />
            <br>
            <v-text-field
                v-model="formData.prize.title"
                :rules="emptyRule"
                outlined
                label="標題"
            />
            <v-textarea
                v-model="formData.prize.description"
                :rules="emptyRule"
                outlined
                label="內容"
            />
            <v-divider class="my-5" />
            <strong class="mb-2 block">競賽日期及地點</strong>
            <ol>
                <li
                    v-for="(item, i) in competitionList"
                    :key="i"
                >
                    <table>
                        <tr>
                            <th>名稱：</th>
                            <td>{{ item.name }}</td>
                        </tr>
                        <tr
                            v-for="(addr, j) in item.locations"
                            :key="j"
                        >
                            <th>
                                <template v-if="!j">
                                    地點：
                                </template>
                            </th>
                            <td>{{ addr.name }}（{{ addr.address }}）</td>
                        </tr>
                    </table>
                </li>
            </ol>
            <!-- <v-divider class="my-5" />
            <strong class="mb-2 block">應試節次圖</strong>
            <ol>
                <li
                    v-for="(url, i) in formData.examTimetables"
                    :key="i"
                >
                    <div class="flex items-center">
                        <FileUploader
                            v-model="formData.examTimetables[i]"
                            :rules="emptyRule"
                            accept="image/*"
                            class="flex-1"
                        />
                        <v-btn
                            dark
                            small
                            rounded
                            class="ml-5 block"
                            @click="formData.examTimetables.splice(i, 1)"
                        >
                            刪除此圖
                        </v-btn>
                    </div>
                </li>
            </ol>
            <v-btn
                dark
                small
                rounded
                color="primary"
                class="ml-auto block"
                :disabled="formData.examTimetables.some((url) => !url)"
                @click="formData.examTimetables.push('')"
            >
                新增節次圖
                <v-icon>mdi-plus</v-icon>
            </v-btn> -->
        </v-form>
        <div class="d-flex justify-center mt-5">
            <v-btn
                color="primary"
                @click="handleSubmit()"
            >
                儲存
            </v-btn>
        </div>
    </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import ImageUploader from '@/components/ImageUploader.vue';
import _ from 'lodash';

const initData = () => ({
    qualification: '',
    fee: '',
    giveaway: {
        thumb: '',
        title: '',
        description: '',
    },
    prize: {
        thumb: '',
        title: '',
        description: '',
    },
    examTimetables: [''],
});

export default {
    components: {
        ImageUploader,
    },
    props: {
        activityId: {
            type: String,
            required: true,
        },
    },
    data() {
        return {
            dialogVisible: false,
            formData: initData(),
            emptyRule: [(v) => !!v || '此欄位為必填'],
        };
    },
    computed: {
        ...mapState('mActivity', ['activityData']),
        ...mapState('mCompetition', ['competitionList']),
    },
    created() {
        this['mActivity/Fetch'](this.activityId).then((res) => {
            _.assign(this.formData, res.data);
        });
        this['mCompetition/List']({
            filter: `activity:${this.activityId}`,
        });
    },
    methods: {
        ...mapActions(['mCompetition/List', 'mActivity/Fetch', 'mActivity/Update']),
        handleSubmit() {
            if (this.$refs.form.validate()) {
                this['mActivity/Update']({
                    id: this.activityId,
                    data: this.formData,
                }).then(() => this['mActivity/Fetch'](this.activityId));
            }
        },
    },
};
</script>

<style lang="scss" scoped>
th,
td {
    vertical-align: baseline;
}
</style>
