var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"history-page grid gap-2"},[_c('div',{staticClass:"flex items-center justify-between pt-2"},[_c('div',[_c('v-select',{staticStyle:{"width":"500px"},attrs:{"items":[{text:'全部',value:''},..._vm.activityOptions],"item-text":"text","item-value":"value","label":"選擇活動","outlined":""},on:{"change":function($event){return _vm.getList()}},model:{value:(_vm.currentActivity),callback:function ($$v) {_vm.currentActivity=$$v},expression:"currentActivity"}})],1),_c('v-dialog',{attrs:{"max-width":"600","persistent":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"primary"},on:{"click":function($event){_vm.formData.id = ''}}},'v-btn',attrs,false),on),[_vm._v(" 新增項目 "),_c('v-icon',[_vm._v("mdi-plus")])],1)]}}]),model:{value:(_vm.dialogVisible),callback:function ($$v) {_vm.dialogVisible=$$v},expression:"dialogVisible"}},[[_c('v-card',{staticClass:"p-5"},[_c('v-form',{ref:"form",attrs:{"lazy-validation":""}},[_c('v-select',{attrs:{"items":_vm.activityOptions,"rules":_vm.emptyRule,"item-text":"text","item-value":"value","label":"選擇活動","outlined":""},model:{value:(_vm.formData.activity),callback:function ($$v) {_vm.$set(_vm.formData, "activity", $$v)},expression:"formData.activity"}}),_c('v-select',{attrs:{"items":[
                                '一年級',
                                '二年級',
                                '三年級',
                                '四年級',
                                '五年級',
                                '六年級',
                            ],"rules":_vm.emptyRule,"label":"選擇年級","outlined":""},model:{value:(_vm.formData.grade),callback:function ($$v) {_vm.$set(_vm.formData, "grade", $$v)},expression:"formData.grade"}}),_c('v-select',{attrs:{"items":['國文', '英文', '數學', '自然', '智力測驗'],"rules":_vm.emptyRule,"label":"選擇科目","outlined":""},model:{value:(_vm.formData.subject),callback:function ($$v) {_vm.$set(_vm.formData, "subject", $$v)},expression:"formData.subject"}}),_c('v-text-field',{attrs:{"rules":_vm.emptyRule,"outlined":"","label":"排序權重欄位"},model:{value:(_vm.formData.weight),callback:function ($$v) {_vm.$set(_vm.formData, "weight", $$v)},expression:"formData.weight"}}),_c('FileUploader',{attrs:{"rules":_vm.emptyRule,"label":"檔案（PDF）","accept":".pdf"},model:{value:(_vm.formData.pdfLink),callback:function ($$v) {_vm.$set(_vm.formData, "pdfLink", $$v)},expression:"formData.pdfLink"}})],1),_c('v-card-actions',{staticClass:"justify-end"},[_c('v-btn',{attrs:{"text":""},on:{"click":function($event){return _vm.closeModal()}}},[_vm._v(" 取消 ")]),_c('v-btn',{attrs:{"color":"primary"},on:{"click":function($event){return _vm.handleSubmit()}}},[_vm._v(" 儲存 ")])],1)],1)]],2)],1),_c('div',[_c('v-btn-toggle',{on:{"change":function($event){return _vm.getList()}},model:{value:(_vm.currentTab),callback:function ($$v) {_vm.currentTab=$$v},expression:"currentTab"}},[_vm._l((_vm.menu),function(item){return [_c('v-btn',{key:item.text,attrs:{"value":item.value}},[_vm._v(" "+_vm._s(item.text)+" ")])]})],2)],1),_c('v-data-table',{attrs:{"disable-sort":"","items":_vm.list,"headers":[
            { text: '活動', value: 'activity' },
            { text: '年級', value: 'grade' },
            { text: '科目', value: 'subject' },
            { text: '權重', value: 'weight' },
            { text: '', value: 'actions', sortable: false },
        ],"server-items-length":_vm.total,"options":_vm.paginationOptions,"footer-props":{
            'items-per-page-options': [5, 10, 50, 100],
        }},on:{"update:options":function($event){_vm.paginationOptions=$event}},scopedSlots:_vm._u([{key:"item.actions",fn:function({ item }){return [_c('v-icon',{staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){return _vm.editItem(item)}}},[_vm._v(" mdi-pencil ")]),_c('v-icon',{attrs:{"small":""},on:{"click":function($event){return _vm.deleteItem(item)}}},[_vm._v(" mdi-delete ")])]}}])})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }