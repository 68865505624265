<template>
    <div class="grid gap-2 max-w-xl">
        <v-form
            ref="form"
            lazy-validation
        >
            <strong class="mb-2 block">各年級整體分數統計數據<small>*建議檔案大小2mb以下</small></strong>
            <v-card
                v-for="(item, i) in formData.result"
                :key="i"
                class="p-5 my-5"
            >
                <v-select
                    v-model="formData.result[i].grade"
                    :items="gradeOptions"
                    :rules="emptyRule"
                    item-text="text"
                    item-value="value"
                    label="選擇年級"
                    outlined
                />
                <ImageUploader v-model="formData.result[i].image" />
                <br>
                <v-card-actions class="justify-end">
                    <v-btn
                        dark
                        small
                        rounded
                        class="ml-5 block"
                        @click="formData.result.splice(i, 1)"
                    >
                        刪除
                    </v-btn>
                </v-card-actions>
            </v-card>
            <br>
            <v-btn
                dark
                small
                rounded
                color="primary"
                class="ml-auto block"
                @click="formData.result.push({
                    grade: '',
                    image: ''
                })"
            >
                新增
                <v-icon>mdi-plus</v-icon>
            </v-btn>
        </v-form>
        <div class="d-flex justify-center">
            <v-btn
                color="primary"
                @click="handleSubmit()"
            >
                儲存
            </v-btn>
        </div>
    </div>
</template>

<script>
import ImageUploader from '@/components/ImageUploader.vue';
import _ from 'lodash';
import { mapActions, mapState } from 'vuex';

const initData = () => ({
    result: [{
        grade: '四年級',
        image: '',
    }, {
        grade: '五年級',
        image: '',
    }, {
        grade: '六年級',
        image: '',
    }],
});

export default {
    components: {
        ImageUploader,
    },
    props: {
        activityId: {
            type: String,
            required: true,
        },
    },
    data() {
        return {
            formData: initData(),
            emptyRule: [(v) => !!v || '此欄位為必填'],
            gradeOptions: [
                { text: '一年級', value: '一年級' },
                { text: '二年級', value: '二年級' },
                { text: '三年級', value: '三年級' },
                { text: '四年級', value: '四年級' },
                { text: '五年級', value: '五年級' },
                { text: '六年級', value: '六年級' },
            ],
        };
    },
    computed: {
        ...mapState('mActivity', ['activityData']),
        ...mapState('mCompetition', ['competitionList']),
    },
    created() {
        this['mActivity/Fetch'](this.activityId).then((res) => {
            _.assign(this.formData, res.data);
        });
        this['mCompetition/List']({
            filter: `activity:${this.activityId}`,
        });
    },
    methods: {
        ...mapActions(['mCompetition/List', 'mActivity/Fetch', 'mActivity/Update']),
        handleSubmit() {
            if (this.$refs.form.validate()) {
                this['mActivity/Update']({
                    id: this.activityId,
                    data: this.formData,
                }).then(() => this['mActivity/Fetch'](this.activityId));
            }
        },
    },
};
</script>

<style lang="scss" scoped>
th,
td {
    vertical-align: baseline;
}
</style>
