import Vue from 'vue';
import axios from 'axios';
import { Loading } from 'element-ui';
import FormData from '@/api/libs/form-data';
import UserAPI from '@/api/libs/user';
import ResourceAPI from '@/api/libs/resource';
import SettingAPI from '@/api/libs/setting';
//
import ApplyUtil from './apply-util';

const baseURL = process.env.VUE_APP_BASE_URL
    ? `https://${process.env.VUE_APP_BASE_URL}`
    // eslint-disable-next-line no-restricted-globals
    : `http://${location.hostname}:8080`;
const getOptions = () => {
    // eslint-disable-next-line no-undef
    const token = localStorage.getItem('token');
    return {
        baseURL,
        headers: {
            ...token ? { authorization: `Bearer ${token}` } : {},
        },
    };
};

let loading;
axios.interceptors.request.use(
    (config) => {
        loading = Loading.service({ lock: true }); // loading 畫面
        return config;
    },
    (error) => {
        loading.close();
        Promise.reject(error);
    },
);
axios.interceptors.response.use(
    (response) => {
        loading.close();
        return response;
    },
    (error) => {
        loading.close();
        if (error.response?.status === 401) {
            Vue.prototype.$bus.$emit('updateToken', '');
        }
        return Promise.reject(error);
    },
);

const API = {
    Admin: new UserAPI('admin', getOptions),
    Activity: new ResourceAPI('activity', getOptions),
    ActivityRange: new ResourceAPI('activity/range', getOptions),
    ActivityResult: new ResourceAPI('activity/result', getOptions),
    Carousel: new ResourceAPI('carousel', getOptions),
    Competition: new ResourceAPI('competition', getOptions),
    Question: new ResourceAPI('question', getOptions),
    Schedule: new ResourceAPI('schedule', getOptions),
    Setting: new SettingAPI('setting', getOptions),
    Apply: new ResourceAPI('apply', getOptions),
    ApplyUtil: ApplyUtil(getOptions),
    PostExam: new ResourceAPI('past-exam', getOptions),
    Static: (url) => (/^(blob|http)/.test(url) ? url : `${baseURL}${url}`),
    FormData,
};

function AutoRefreshToken(role, intervalTime) {
    role.AutoRefreshToken().catch(() => {});
    setInterval(() => {
        role.AutoRefreshToken().catch(() => {});
    }, intervalTime);
}

AutoRefreshToken(API.Admin, 60 * 60 * 1000); // Admin 一小時檢查一次 Token
export default API;
