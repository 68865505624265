<template>
    <div class="grid gap-2">
        <div class="flex justify-end">
            <v-dialog
                v-model="dialogVisible"
                max-width="600"
                persistent
            >
                <template v-slot:activator="{ on, attrs }">
                    <v-btn
                        v-bind="attrs"
                        color="primary"
                        v-on="on"
                    >
                        新增項目
                        <v-icon>mdi-plus</v-icon>
                    </v-btn>
                </template>
                <template>
                    <v-card class="p-5">
                        <v-form
                            ref="form"
                            lazy-validation
                        >
                            <DatePicker
                                v-if="isDate"
                                v-model="formData.date"
                                :rules="emptyRule"
                                label="日期"
                            />
                            <v-text-field
                                v-model="formData.name"
                                :rules="emptyRule"
                                outlined
                                label="競賽名稱"
                            />
                            <v-text-field
                                v-model="formData.time"
                                :rules="emptyRule"
                                outlined
                                label="競賽時間"
                            />
                            <strong class="mb-2 block">考場選項</strong>
                            <ol>
                                <li
                                    v-for="(item, i) in formData.locations"
                                    :key="i"
                                >
                                    <v-btn
                                        dark
                                        small
                                        rounded
                                        class="ml-auto block mb-2"
                                        @click="formData.locations.splice(i, 1)"
                                    >
                                        刪除「{{ item.name }}」考場
                                    </v-btn>
                                    <v-text-field
                                        v-model="formData.locations[i].name"
                                        :rules="emptyRule"
                                        outlined
                                        label="地點名稱"
                                    />
                                    <v-text-field
                                        v-model="formData.locations[i].address"
                                        :rules="emptyRule"
                                        outlined
                                        label="地址"
                                    />
                                    <v-text-field
                                        v-model="formData.locations[i].googleMap"
                                        :rules="emptyRule"
                                        outlined
                                        label="Google maps 連結網址"
                                    />
                                    <FileUploader
                                        v-model="formData.locations[i].planimetricMap"
                                        :rules="emptyRule"
                                        label="試場平面圖（PDF）"
                                        accept=".pdf"
                                    />
                                </li>
                            </ol>
                            <v-btn
                                dark
                                small
                                rounded
                                color="primary"
                                class="ml-auto block"
                                :disabled="formData.locations.some(l => !l.name)"
                                @click="formData.locations.push(locationSchema())"
                            >
                                新增考場
                                <v-icon>mdi-plus</v-icon>
                            </v-btn>
                            <br>
                            <v-text-field
                                v-model="formData.fee"
                                :rules="emptyRule"
                                outlined
                                label="報名費用"
                            />
                            <v-text-field
                                v-model="formData.weight"
                                :rules="emptyRule"
                                outlined
                                label="排序權重欄位"
                            />
                            <strong class="mb-2 block">應試節次圖</strong>
                            <div class="flex items-center">
                                <FileUploader
                                    v-model="formData.examTimetable"
                                    :rules="emptyRule"
                                    accept="image/*"
                                    class="flex-1"
                                />
                            </div>
                        </v-form>
                        <v-card-actions class="justify-end">
                            <v-btn
                                text
                                @click="closeModal()"
                            >
                                取消
                            </v-btn>
                            <v-btn
                                color="primary"
                                @click="handleSubmit()"
                            >
                                儲存
                            </v-btn>
                        </v-card-actions>
                    </v-card>
                </template>
            </v-dialog>
        </div>
        <v-data-table
            disable-sort
            :items="list"
            :headers="[
                { text: '日期', value: 'date' },
                { text: '名稱', value: 'name' },
                { text: '費用', value: 'fee' },
                { text: '權重', value: 'weight' },
                { text: '', value: 'actions', sortable: false },
            ]"
            :server-items-length="total"
            :options.sync="paginationOptions"
            :footer-props="{
                'items-per-page-options': [5, 10, 50, 100],
            }"
        >
            <template v-slot:item.date="{ item }">
                {{ item.date | formatDate }}
            </template>
            <template v-slot:item.actions="{ item }">
                <v-icon
                    small
                    class="mr-2"
                    @click="editItem(item)"
                >
                    mdi-pencil
                </v-icon>
                <v-icon
                    small
                    @click="deleteItem(item)"
                >
                    mdi-delete
                </v-icon>
            </template>
        </v-data-table>
    </div>
</template>

<script>
import { mapActions } from 'vuex';
import { MessageBox } from 'element-ui';
import _ from 'lodash';
import DatePicker from '@/components/VDatePicker.vue';
import FileUploader from '@/components/FileUploader.vue';

const locationSchema = () => ({
    name: '',
    address: '',
    googleMap: '',
    planimetricMap: '',
});
const initData = () => ({
    id: '',
    activity: '',
    date: '',
    time: '',
    name: '',
    locations: [
        locationSchema(),
    ],
    fee: 0,
    weight: 1,
    examTimetable: '',
});

export default {
    components: {
        DatePicker,
        FileUploader,
    },
    props: {
        activityId: {
            type: String,
            required: true,
        },
    },
    data() {
        return {
            isDate: true,
            locationSchema,
            dialogVisible: false,
            formData: initData(),
            emptyRule: [(v) => !!v || '此欄位為必填'],
            paginationOptions: {
                page: 1,
                itemsPerPage: 10,
            },
            total: 0,
            list: [],
        };
    },
    watch: {
        paginationOptions: {
            handler() {
                this.getList();
            },
            deep: true,
        },
    },
    created() {
        this.getList();
    },
    methods: {
        ...mapActions('mCompetition', ['List', 'Create', 'Delete', 'Update']),
        getList() {
            const { page, itemsPerPage } = this.paginationOptions;
            this.List({
                offset: (page - 1) * itemsPerPage,
                limit: itemsPerPage,
                filter: `activity:${this.activityId}`,
            }).then((res) => {
                this.list = res.data.raw;
                this.total = res.data.total;
            });
        },
        clearForm() {
            this.formData = initData();
            this.isDate = false;
            setTimeout(() => {
                this.$refs.form.reset();
                this.formData.date = undefined;
                this.isDate = true;
            }, 100);
        },
        closeModal() {
            this.clearForm();
            this.dialogVisible = false;
        },
        handleSubmit() {
            if (this.$refs.form.validate()) {
                if (this.formData.id) {
                    this.Update({
                        id: this.formData.id,
                        data: this.formData,
                    })
                        .then(this.getList)
                        .then(this.closeModal);
                } else {
                    this.Create({
                        ...this.formData,
                        activity: this.activityId,
                    })
                        .then(this.getList)
                        .then(this.closeModal);
                }
            }
        },
        editItem(item) {
            _.assign(this.formData, item);
            this.dialogVisible = true;
        },
        deleteItem(item) {
            MessageBox.confirm(item.name, {
                type: 'warning',
                title: '確認刪除？',
                confirmButtonText: '刪除',
                cancelButtonText: '取消',
            })
                .then(() => {
                    this.Delete(item.id).then(this.getList);
                })
                .catch(() => {});
        },
    },
};
</script>

<style lang="scss" scoped></style>
