<template>
    <v-menu
        ref="menu"
        v-model="menuVisible"
        :close-on-content-click="false"
        :return-value.sync="date"
        transition="scale-transition"
        offset-y
        min-width="auto"
    >
        <template v-slot:activator="{ on, attrs }">
            <v-text-field
                v-model="date"
                :rules="rules"
                :label="label"
                prepend-icon="mdi-calendar"
                readonly
                v-bind="attrs"
                v-on="on"
            />
        </template>
        <v-date-picker
            v-model="date"
            no-title
            scrollable
        >
            <v-spacer />
            <v-btn
                text
                color="primary"
                @click="menuVisible = false"
            >
                取消
            </v-btn>
            <v-btn
                text
                color="primary"
                @click="$refs.menu.save(date)"
            >
                確認
            </v-btn>
        </v-date-picker>
    </v-menu>
</template>

<script>
import moment from 'moment';

export default {
    model: {
        prop: 'modelValue',
        event: 'change',
    },
    props: {
        modelValue: {
            type: [Array, String],
            default: () => '',
        },
        rules: {
            type: [Array, Object],
            default: () => [],
        },
        label: {
            type: String,
            default: () => '選擇日期',
        },
    },
    data() {
        return {
            menuVisible: false,
        };
    },
    computed: {
        date: {
            get() {
                return moment(this.modelValue || undefined).format('YYYY-MM-DD');
            },
            set(val) {
                this.$emit('change', moment(val).format('YYYY-MM-DD'));
            },
        },
    },
};
</script>

<style lang="scss" scoped></style>
